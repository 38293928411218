import { createSelector } from "reselect";
import { List } from "immutable";
/**
 * Top level selector for uiState
 * @param {Immutable.Map} state
 * @return {Immutable.Map}
 */
export const getUiState = state => state.get("uiState");
/**
 * Gets the current display mode
 * @param {Immutable.Map} state
 * @return {String}
 */
export const getDisplayMode = createSelector(getUiState, uiState => uiState.get("displayMode"));
////// ELP SELECTORS //////
/**
 * Gets the entire elp data, incl for go-out
 * & stay-in
 * @param {Immutable.Map} state
 * @return {Immutable.Map}
 */
export const getElpData = state => state.get("elpData");
/**
 * Gets the current elp data only, i.e,
 * for go out / stay in only, based on the current
 * selected display mode
 * @return {Immutable.Map}
 */
export const getCurrentElpData = createSelector(getDisplayMode, getElpData, (displayMode, elpData) => elpData.get(displayMode));
export const getElpMasterList = createSelector(getCurrentElpData, elpData => {
    return elpData.getIn(["list", "masterList"]);
});
export const getELPDataRequested = createSelector(getCurrentElpData, elpData => {
    return elpData.get("dataRequested");
});
export const getELPDataReceived = createSelector(getCurrentElpData, elpData => {
    return elpData.get("dataReceived");
});
export const getELPBanners = state => state.getIn(["elpData", "go-out", "banners"], List([]));
//Getting this data from go out because this data consists on randomly selected 6 topics.
//If we get data from current selected mode, these 6 topics may be different.
export const getDigitalEventsCarouselData = createSelector(getElpData, elpData => {
    return elpData.getIn(["go-out", "digitalEventsCarousel"]);
});
export const getDigitalEventsViewDescription = createSelector(getDigitalEventsCarouselData, digitalEventsCarouselData => {
    return digitalEventsCarouselData.get("description");
});
export const getDigitalEventsCarouselItems = createSelector(getDigitalEventsCarouselData, digitalEventsCarouselData => {
    return digitalEventsCarouselData.get("digitalEventsCarouselItems");
});
export const getSlugFromProps = (_, slug) => slug;
export const getDigitalListTitle = createSelector(getDigitalEventsCarouselItems, getSlugFromProps, (items, slug) => {
    if (!items) {
        return;
    }
    const item = items.find(item => item.get("url").includes(slug));
    return item ? item.get("title") : slug;
});
export const getCurrentBanners = createSelector(getCurrentElpData, elpData => {
    return elpData.get("banners");
});
export const getTitleFromSlugDetails = createSelector(getCurrentElpData, getSlugFromProps, (elpData, section) => {
    // section is tagSlug / brandSlug of the dynamic list page
    return elpData.getIn(["slugDetails", "name"], section);
});
export const getELPSectionDataReceived = createSelector(getElpData, getSlugFromProps, (elpData, section) => {
    return elpData.getIn([section, "dataReceived"]);
});
export const getELPSectionDataRequested = createSelector(getElpData, getSlugFromProps, (elpData, section) => {
    return elpData.getIn([section, "dataRequested"]);
});
export const getMonumentsDataRequested = createSelector(getElpData, elpData => {
    return elpData.get("monumentsDataRequested");
});
export const getMonumentsDataReceived = createSelector(getElpData, elpData => {
    return elpData.get("monumentsDataReceived");
});

import { Map, List } from "immutable";
import { RECEIVE_GROUPS_CATEGORIES_DATA } from "../actions/index";
const initialState = new Map({
    groups: new List([]),
    categories: new List([])
});
const routes = (state = initialState, action) => {
    switch (action.type) {
        case RECEIVE_GROUPS_CATEGORIES_DATA: {
            return state.withMutations(m => m
                .set("groups", action.payload.getIn(["data", "groups"]))
                .set("categories", action.payload.getIn(["data", "category"])));
        }
        default:
            return state;
    }
};
export default routes;

import { Map, fromJS } from "immutable";
import unescape from "lodash/string/unescape";
const getInitialState = () => {
    let initialRootState;
    try {
        const element = document.getElementById("initial-state");
        if (!element) {
            return;
        }
        const content = element.getAttribute("content");
        const unescaped = unescape(content);
        const result = JSON.parse(unescaped);
        initialRootState = fromJS(result);
    }
    catch (e) {
        console.error("Could not hydrate initial state from DOM");
        console.error(e);
        if (typeof _errs)
            _errs && _errs.push(e);
        if (typeof window)
            window.href.location = "/not-found";
        initialRootState = new Map();
    }
    return initialRootState;
};
export default getInitialState;

/** @jsx jsx */
import { jsx, css } from "@emotion/core";
import React, { Fragment } from "react";
import Navbar from "../../../containers/NavBarWithSubscription";
import CitySelector from "../../../containers/CitySelectorWithSubscription";
import { HideListYourEvent, NavItem } from "./style";
import { sendClevertapEvent } from "../../../helpers/misc";
import { CLEVERTAP_ACTIONS, EVENT_CONTEXTS, EVENT_CATEGORIES, GA_ACTIONS, GENERAL_ACTIONS, HIT_TYPES } from "../../../helpers/constants";
import AppBarSearchIcon from "../../../containers/AppBarSearchIconWithSubscription";
import { FavouriteIcon } from "../../ui/icons/FavouriteIcon";
import Cart from "../../../containers/CartWithSubscription";
import UserIcon from "../../../containers/UserIconWithSubscription";
import { loginCallback } from "../../../helpers/login";
const { CLICK, FAVOURITE, SEND, LOGIN } = GENERAL_ACTIONS, { FAVOURITES_LIST_ICON_CLICK, FAVOURITES_LOGIN_CLICK } = CLEVERTAP_ACTIONS, { FAVOURITES_LIST_CLICK, FAVOURITES_LIST_LOGIN_CLICK } = GA_ACTIONS, { LIST_EVENT_CLICK, WE_ARE_HIRING } = EVENT_CATEGORIES, { EVENT } = HIT_TYPES;
const HideFavouriteIcon = `
	@media screen and (max-width: 345px) {
		display: none;
	}
`;
const FavouriteIconStyles = `
	padding: 7px;	

	@media screen and (max-width: 525px) {
		margin-left: 0;
	}
`;
const UserNavbar = ({ isUserSelectingCity, isLoggedIn, setModalRedirectUrl, setModalSource, setModalType, redirectUrl, resetModalType, setLoginError, setUserLoginData, clearNotifications, notify, location }) => {
    const favouritesListUrl = "/users/me/favourites";
    const navItems = [
        {
            title: "List your event",
            link: "/list-your-events",
            target: "_blank",
            onClick: () => {
                window.ga &&
                    window.ga(SEND, {
                        hitType: EVENT,
                        eventCategory: LIST_EVENT_CLICK,
                        eventAction: CLICK,
                        eventLabel: window.location.pathname
                    });
                sendClevertapEvent(LIST_EVENT_CLICK, {
                    context: EVENT_CONTEXTS.HEADER,
                    url: window.location.pathname
                });
            },
            activeColor: "white"
        },
        {
            title: "Work with us",
            link: "https://www.zomato.com/careers",
            target: "_blank",
            onClick: () => {
                window.ga &&
                    window.ga(SEND, {
                        hitType: EVENT,
                        eventCategory: WE_ARE_HIRING,
                        eventAction: CLICK,
                        eventLabel: window.location.pathname
                    });
            },
            activeColor: "white"
        }
    ];
    const favouriteLogin = (evt, redirectUrl) => {
        const pathname = window.location.pathname;
        if (isLoggedIn) {
            // send GA (Google Analytics) + CleverTap Objects
            sendClevertapEvent(FAVOURITES_LIST_ICON_CLICK, {
                action: CLICK,
                context: EVENT_CONTEXTS.HEADER,
                url: pathname
            });
            window.ga &&
                window.ga(SEND, {
                    hitType: EVENT,
                    eventCategory: FAVOURITE,
                    eventAction: FAVOURITES_LIST_CLICK,
                    eventLabel: pathname
                });
            return false;
        }
        else {
            evt.preventDefault();
            // send GA (Google Analytics) + CleverTap Objects
            sendClevertapEvent(FAVOURITES_LOGIN_CLICK, {
                action: CLICK,
                context: EVENT_CONTEXTS.HEADER,
                url: pathname
            });
            window.ga &&
                window.ga(SEND, {
                    hitType: EVENT,
                    eventCategory: FAVOURITE,
                    eventAction: FAVOURITES_LIST_LOGIN_CLICK,
                    eventLabel: pathname
                });
            document.documentElement.classList.add("no-scroll");
            setModalRedirectUrl(redirectUrl);
            setModalSource("navbar");
            return setModalType({
                modalType: FAVOURITE,
                loginText: FAVOURITE,
                context: EVENT_CONTEXTS.HEADER,
                loginCallback: (userData) => loginCallback(userData, setUserLoginData, setLoginError, resetModalType, notify, clearNotifications, redirectUrl)
            });
        }
    };
    const login = (evt) => {
        if (isLoggedIn) {
            return false;
        }
        else {
            evt.preventDefault();
            document.documentElement.classList.add("no-scroll");
            return setModalType({
                modalType: LOGIN,
                context: EVENT_CONTEXTS.HEADER,
                loginCallback: (userData) => loginCallback(userData, setUserLoginData, setLoginError, resetModalType, notify, clearNotifications, redirectUrl)
            });
        }
    };
    const currentPath = location.pathname + location.search;
    const isEDP = location.pathname.endsWith("/event");
    const favoriteIcon = (<div css={css([NavItem(currentPath === favouritesListUrl ? "white" : undefined), FavouriteIconStyles])}>
			<FavouriteIcon />
		</div>);
    return (<Fragment>
			<Navbar items={navItems} overideCss={HideListYourEvent}/>
			<AppBarSearchIcon />
			{/* {isEDP &&
            (!isUserSelectingCity ? (
                <InsiderLink
                    onClick={e => favouriteLogin(e, favouritesListUrl)}
                    href={favouritesListUrl}
                    css={css([HideFavouriteIcon])}
                >
                    {favoriteIcon}
                </InsiderLink>
            ) : (
                { favoriteIcon }
            ))} */}
			<CitySelector />
			<Cart />
			<UserIcon login={login}/>
		</Fragment>);
};
export default React.memo(UserNavbar);

import { Map, List, fromJS } from "immutable";
import { RECEIVE_TICKETS_DATA, CLEAR_TICKETS_DATA, RECEIVE_TICKETS_FROM_SHORTCODE, ERROR_TICKETS_DATA, RECEIVE_TICKET_PURCHASE_STATUS, RECEIVE_MORE_TICKETS_DATA, REQUEST_MORE_TICKETS_DATA, ERROR_MORE_TICKETS_DATA, } from "../actions/index";
const initialState = new Map({
    data: List([]),
    ticketData: Map(),
    ticketDetailDataReceived: false,
    ticketDataReceived: false,
    ticketDataErrored: false,
    hasMoreTickets: true,
    currentPage: 0,
    loadingMoreTickets: false,
});
const tickets = (state = initialState, action) => {
    switch (action.type) {
        case RECEIVE_TICKETS_DATA: {
            return state.withMutations(s => {
                s.setIn(["data"], action.payload).setIn(["ticketDataReceived"], true);
                if (s.getIn(["data"]).size < 10)
                    s.setIn(["hasMoreTickets"], false);
            });
        }
        case REQUEST_MORE_TICKETS_DATA: {
            return state.setIn(["loadingMoreTickets"], true);
        }
        case RECEIVE_MORE_TICKETS_DATA: {
            return state.withMutations(s => {
                const existingTicket = s.getIn(["data"]);
                const newTicket = fromJS(action.payload);
                s.setIn(["data"], existingTicket.concat(newTicket))
                    .updateIn(["currentPage"], page => page + 1)
                    .setIn(["loadingMoreTickets"], false);
                if (newTicket.size < 10)
                    s.setIn(["hasMoreTickets"], false);
            });
        }
        case CLEAR_TICKETS_DATA: {
            return new List([]);
            return state.withMutations(s => s.setIn(["data"], new List([])).setIn(["ticketDataReceived"], false));
        }
        case ERROR_TICKETS_DATA: {
            return state.withMutations(s => s.setIn(["data"], action.payload).setIn(["ticketDataErrored"], true));
        }
        case ERROR_MORE_TICKETS_DATA: {
            return state.withMutations(s => s.setIn(["data"], s.getIn(["data"])).setIn(["hasMoreTickets"], false));
        }
        case RECEIVE_TICKETS_FROM_SHORTCODE: {
            return state.withMutations(s => s.setIn(["ticketData"], action.payload).setIn(["ticketDetailDataReceived"], true));
        }
        case RECEIVE_TICKET_PURCHASE_STATUS: {
            return state.withMutations(s => s.setIn(["ticketPurchasedStatus"], action.payload).setIn(["ticketPurchasedStatusReceived"], true));
        }
        default:
            return state;
    }
};
export default tickets;

import { createSelector } from "reselect";
import { List } from "immutable";
// helpers
import { HOME_PAGE_BANNER_GROUP_ID } from "../helpers/constants";
/**
 * Returns the selected tabs for each
 * display mode
 * @param {Immutable.Map} state
 * @return {Immutable.Map}
 */
export const getAllSelectedTabs = state => state.getIn(["uiState", "selectedTabs"]);
/**
 * Top level selector for uiState
 * @param {Immutable.Map} state
 * @return {Immutable.Map}
 */
export const getUiState = state => state.get("uiState");
/**
 * Gets the current display mode
 * @param {Immutable.Map} state
 * @return {String}
 */
export const getDisplayMode = createSelector(getUiState, uiState => uiState.get("displayMode"));
/**
 * Gets the tab selected for the current
 * display mode
 * @return {String}
 */
export const getSelectedTabName = createSelector(getDisplayMode, getAllSelectedTabs, (displayMode, allSelectedTabs) => allSelectedTabs.get(displayMode));
////// HOMEPAGE SELECTORS //////
/**
 * Gets the entire homepage data, incl for go-out
 * & stay-in
 * @param {Immutable.Map} state
 * @return {Immutable.Map}
 */
export const getHomepageData = state => state.get("homepageData");
/**
 * Gets the current homepage data only, i.e,
 * for go out / stay in only, based on the current
 * selected display mode
 * @return {Immutable.Map}
 */
export const getCurrentHomepageData = createSelector(getDisplayMode, getHomepageData, (displayMode, homepageData) => homepageData.get(displayMode));
export const getMasterList = createSelector(getCurrentHomepageData, homepageData => {
    return homepageData.getIn(["list", "masterList"]);
});
export const getStayInData = state => state.getIn(["homepageData", "stay-in-carousel"]);
export const getBanners = state => state.getIn(["homepageData", "go-out", "banners"], List([]));
export const getBannersForHomePage = createSelector(getBanners, banners => {
    return banners.filter((b) => b.getIn(["group_id", "_id"]) === HOME_PAGE_BANNER_GROUP_ID);
});
export const getHomepageDataRequested = createSelector(getCurrentHomepageData, homepageData => {
    return homepageData.get("dataRequested");
});
export const getHomepageDataReceived = createSelector(getCurrentHomepageData, homepageData => {
    return homepageData.get("dataReceived");
});
//Getting this data from go out because this data consists on randomly selected 6 topics.
//If we get data from current selected mode, these 6 topics may be different.
export const getDigitalEventsCarouselData = createSelector(getHomepageData, homePageData => {
    return homePageData.getIn(["go-out", "digitalEventsCarousel"]);
});
export const getDigitalEventsCarouselItems = createSelector(getDigitalEventsCarouselData, digitalEventsCarouselData => {
    return digitalEventsCarouselData.get("digitalEventsCarouselItems");
});

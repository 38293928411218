import { List, Map } from "immutable";
import { CLEAR_ALL_ARTISTS_DATA, CLEAR_ARTIST_DATA, RECEIVE_ALL_ARTISTS_DATA, RECEIVE_ARTIST_DATA, RECEIVE_ARTISTS_DATA, RECEIVE_PARTNER_UPDATES } from "../actions/index";
import { normalize, normalizePartner, normalizePartnerUpdates } from "../selectors/partner";
const initialState = new Map({
    list: new List([]),
    all: new List([]),
    artist: new Map(),
    updates: new Map()
});
const artists = (state = initialState, action) => {
    switch (action.type) {
        case RECEIVE_ARTIST_DATA: {
            const apiData = normalizePartner(action.payload.get("data"));
            return state.setIn(["artist", apiData.get("slug")], apiData);
        }
        case RECEIVE_ARTISTS_DATA: {
            return state.set("list", normalize(action.payload.getIn(["data", "artists"])));
        }
        case RECEIVE_ALL_ARTISTS_DATA: {
            return state.set("all", normalize(action.payload.getIn(["data", "tags"])));
        }
        case RECEIVE_PARTNER_UPDATES: {
            return state.setIn(["updates", action.payload.getIn(["data", "meta", "0", "slug"])], normalizePartnerUpdates(action.payload.getIn(["data", "list"])));
        }
        case CLEAR_ARTIST_DATA: {
            return state.withMutations(s => s.delete("artist").delete("updates"));
        }
        case CLEAR_ALL_ARTISTS_DATA: {
            return state.set("all", new List([]));
        }
        default:
            return state;
    }
};
export default artists;

import { Map, List } from "immutable";
import { RECEIVE_VENUE_DATA, RECEIVE_VENUES_DATA, RECEIVE_ALL_VENUES_DATA, RECEIVE_PARTNER_UPDATES, CLEAR_VENUE_DATA, CLEAR_ALL_VENUES_DATA } from "../actions/index";
import { pluralize } from "../helpers/constants";
import { normalizePartnerUpdates, normalizePartner } from "../selectors/partner";
const initialState = new Map({
    list: new List([]),
    all: new List([]),
    venue: new Map(),
    updates: new Map()
});
const normalize = venuesData => {
    // if results from network call are empty, store a falsy value.
    if (venuesData.size == 0) {
        return false;
    }
    return venuesData.map(a => {
        let eventCount = a.get("active_events") || a.get("activeEvents");
        let subTextString = pluralize(eventCount, "event");
        return new Map({
            name: a.get("name", ""),
            _id: a.get("_id", ""),
            subtext: subTextString,
            src: a.get("share_image", "https://placeholdit.imgix.net/~text?txtsize=33&txt=350%C3%97150&w=450&h=246"),
            type: "venue",
            slug: a.get("slug"),
            description: a.get("description", "")
        });
    });
};
const venues = (state = initialState, action) => {
    switch (action.type) {
        case RECEIVE_VENUE_DATA: {
            const apiData = normalizePartner(action.payload.get("data"));
            return state.setIn(["venue", apiData.get("slug")], apiData);
        }
        case RECEIVE_VENUES_DATA: {
            return state.set("list", normalize(action.payload.getIn(["data", "venues"])));
        }
        case RECEIVE_ALL_VENUES_DATA: {
            return state.set("all", normalize(action.payload.getIn(["data", "tags"])));
        }
        case RECEIVE_PARTNER_UPDATES: {
            return state.setIn(["updates", action.payload.getIn(["data", "meta", "0", "slug"])], normalizePartnerUpdates(action.payload.getIn(["data", "list"])));
        }
        case CLEAR_VENUE_DATA: {
            return state.withMutations(s => s.delete("venue").delete("updates"));
        }
        case CLEAR_ALL_VENUES_DATA: {
            return state.set("all", new List([]));
        }
        default:
            return state;
    }
};
export default venues;

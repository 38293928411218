import { createSelector } from "reselect";
export const getSlugFromProps = (_, slug) => slug;
export const getAllReminderData = state => {
    return state.getIn(["userData", "reminders"]);
};
export const getReminderEvents = createSelector(getAllReminderData, reminders => {
    return reminders.get("events");
});
export const isReminderSet = createSelector(getReminderEvents, getSlugFromProps, (events, slug) => {
    if (events) {
        return events.includes(slug);
    }
    return false;
});
export const getReminderSetPhone = createSelector(getAllReminderData, reminderData => {
    return reminderData.get("remindToPhone");
});
export const areRemindersRehydrated = createSelector(getAllReminderData, reminderdata => {
    return reminderdata.get("isRehydrated");
});
export const getReminderPhoneAndResources = createSelector(getAllReminderData, reminderData => {
    return reminderData.delete("isRehydrated");
});

/** @jsx jsx */
import { useEffect, useState, useRef } from "react";
import { jsx } from "@emotion/core";
import Observer from "react-intersection-observer";
const getTimeRemaining = (expires, clockSkew) => {
    const now = Date.now() / 1000;
    return Math.round(expires - now + clockSkew);
};
export const useCart = (fetchCart, clearCart, expires, clockSkew) => {
    const [timeRemaining, setTimeRemaining] = useState(0);
    useEffect(() => {
        fetchCart();
    }, []);
    useEffect(() => {
        if (timeRemaining < 0) {
            clearCart();
        }
    }, [timeRemaining]);
    useEffect(() => {
        if (timeRemaining >= 0) {
            setTimeout(() => {
                if (expires && clockSkew) {
                    const t = getTimeRemaining(expires, clockSkew);
                    setTimeRemaining(t);
                }
            }, 1000);
        }
    }, [timeRemaining, expires, clockSkew]);
    return timeRemaining;
};
// Effect wont be call on first render
export const useDidUpdateEffect = (fn, inputs) => {
    const didMountRef = useRef(false);
    useEffect(() => {
        if (didMountRef.current)
            fn();
        else
            didMountRef.current = true;
    }, inputs);
};
// updated version of instersection observer library provides hooks
export const withIntersectionObserver = (Component, setInView) => (<Observer onChange={inView => {
        if (!inView) {
            setInView(false);
        }
        else {
            setInView(true);
        }
    }}>
		{Component}
	</Observer>);
export const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
};

import { Map, List } from "immutable";
import { REQUEST_DYNAMIC_LANDING_PAGE_EVENTS_DATA, RECEIVE_DYNAMIC_LANDING_PAGE_EVENTS_DATA, ERROR_DYNAMIC_LANDING_PAGE_EVENTS_DATA, REQUEST_NEWYEAR_LANDING_PAGE_EVENTS_DATA, RECEIVE_NEWYEAR_LANDING_PAGE_EVENTS_DATA, ERROR_NEWYEAR_LANDING_PAGE_EVENTS_DATA, RECEIVE_NEWYEAR_OFFER_DATA, ERROR_NEWYEAR_OFFER_DATA, RECEIVE_MUMBAI_FEST_EVENTS_DATA, ERROR_MUMBAI_FEST_EVENTS_DATA, RECEIVE_HOLI24_OFFER_DATA, ERROR_HOLI24_OFFER_DATA, RECEIVE_IPL_MATCH_SCHEDULE_EVENTS_DATA, ERROR_IPL_MATCH_SCHEDULE_EVENTS_DATA } from "../actions/index";
const initialState = new Map({
    data: List([]),
    dataReceived: false,
    dataErrored: false
});
export const dynamicLandingEvents = (state = initialState, action) => {
    switch (action.type) {
        case RECEIVE_DYNAMIC_LANDING_PAGE_EVENTS_DATA: {
            return state.withMutations(s => s.setIn(["data"], action.payload).setIn(["dataReceived"], true));
        }
        case ERROR_DYNAMIC_LANDING_PAGE_EVENTS_DATA: {
            return state.withMutations(s => s.setIn(["data"], new List([])).setIn(["dataErrored"], true));
        }
        default:
            return state;
    }
};
export const newYearLandingEvents = (state = initialState, action) => {
    switch (action.type) {
        case RECEIVE_NEWYEAR_LANDING_PAGE_EVENTS_DATA: {
            return state.withMutations(s => s.setIn(["data"], action.payload).setIn(["dataReceived"], true));
        }
        case ERROR_NEWYEAR_LANDING_PAGE_EVENTS_DATA: {
            return state.withMutations(s => s.setIn(["data"], new List([])).setIn(["dataErrored"], true));
        }
        default:
            return state;
    }
};
export const newYear24Offers = (state = initialState, action) => {
    switch (action.type) {
        case RECEIVE_NEWYEAR_OFFER_DATA: {
            return state.withMutations(s => s.setIn(["data"], action.payload).setIn(["dataReceived"], true));
        }
        case ERROR_NEWYEAR_OFFER_DATA: {
            return state.withMutations(s => s.setIn(["data"], new List([])).setIn(["dataErrored"], true));
        }
        default:
            return state;
    }
};
export const mumbaiFestEvents = (state = initialState, action) => {
    switch (action.type) {
        case RECEIVE_MUMBAI_FEST_EVENTS_DATA: {
            return state.withMutations(s => s.setIn(["data"], action.payload).setIn(["dataReceived"], true));
        }
        case ERROR_MUMBAI_FEST_EVENTS_DATA: {
            return state.withMutations(s => s.setIn(["data"], new List([])).setIn(["dataErrored"], true));
        }
        default:
            return state;
    }
};
export const holi24Offers = (state = initialState, action) => {
    switch (action.type) {
        case RECEIVE_HOLI24_OFFER_DATA: {
            return state.withMutations(s => s.setIn(["data"], action.payload).setIn(["dataReceived"], true));
        }
        case ERROR_HOLI24_OFFER_DATA: {
            return state.withMutations(s => s.setIn(["data"], new List([])).setIn(["dataErrored"], true));
        }
        default:
            return state;
    }
};
export const iplLandingPage = (state = initialState, action) => {
    switch (action.type) {
        case RECEIVE_IPL_MATCH_SCHEDULE_EVENTS_DATA: {
            return state.withMutations(s => s.setIn(["data"], action.payload).setIn(["dataReceived"], true));
        }
        case ERROR_IPL_MATCH_SCHEDULE_EVENTS_DATA: {
            return state.withMutations(s => s.setIn(["data"], new List([])).setIn(["dataErrored"], true));
        }
        default:
            return state;
    }
};

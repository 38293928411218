import { Map, List, fromJS } from "immutable";
import cookies from "browser-cookies";
import { RECEIVE_PAYMENT_SUCCESS_DATA, CLEAR_PAYMENT_SUCCESS_DATA, REQUEST_PAYMENT_STATUS_DATA, RECEIVE_PAYMENT_STATUS_DATA } from "../actions/index";
import { addGAEvent, GA_RECOMMENDED_EVENTS, CURRENCY_CODE, createCartItemParam } from "../helpers/constants";
import { SET_PRE_PAYMENT_DATA, CLEAR_PRE_PAYMENT_DATA } from "../actions/payments";
import { isFromApp } from "../helpers/misc/common";
import { triggerAppGAAnalytics } from "../helpers/appBridge";
const initialState = new Map({
    transaction: new Map(),
    coupons: new List([]),
    prePaymentData: new Map()
});
const admitAdSuccess = transaction => {
    const showPixel = cookies.get("admitAd") === "true";
    if (!showPixel) {
        return;
    }
    const ADMITAD = window.ADMITAD || {};
    ADMITAD.Invoice = ADMITAD.Invoice || {};
    ADMITAD.Invoice.broker = "adm"; // pass different value if the sale is generated through another source/medium
    ADMITAD.Invoice.category = "1"; // action code (already inserted)
    var orderedItem = []; // temporary array for product items
    transaction.get("events").forEach(event => {
        event.get("items").forEach(item => {
            orderedItem.push({
                Product: {
                    category: "1",
                    // Core returns item price as item price * count
                    // This was handled by a hack 3b6b7e8a90d95964a56a0606ba367f63d6087cde
                    // When payments success was rewritten in React the hack was lost
                    // This was causing incorrect numbers to be reported in Google Analytics
                    // This can't be fixed in core because Master Queue uses the same route
                    price: item.get("price") / item.get("count"),
                    priceCurrency: "INR",
                    eventCity: event.get('city'),
                    eventName: event.get('name'),
                    eventTime: event.get('venue_date_string')
                },
                orderQuantity: item.get("count"),
                additionalType: "sale" // always sale
            });
        });
    });
    ADMITAD.Invoice.referencesOrder = ADMITAD.Invoice.referencesOrder || [];
    // adding items to the order
    ADMITAD.Invoice.referencesOrder.push({
        orderNumber: transaction.get("transaction_id"),
        orderedItem: orderedItem
    });
    // Important! If order data is loaded via AJAX, uncomment this string.
    ADMITAD.Tracking && ADMITAD.Tracking.processPositions && ADMITAD.Tracking.processPositions();
    cookies.erase("admitAd");
};
const onPaymentSuccess = transaction => {
    const success_redirect_url = transaction.getIn(["merchant", "success_redirect_url"]);
    if (success_redirect_url) {
        window.location = `${success_redirect_url}?transactionId=${transaction.get("transaction_id")}`;
    }
    if (window.fbq) {
        fbq("track", "Purchase", { value: transaction.getIn(["amount"]).toString(), currency: "INR" });
    }
    transaction.get("events").forEach(event => {
        if (window.aa) {
            // https://github.com/algolia/search-insights.js
            // Reporting conversion events
            // Upon a conversion event, the API is a bit different and you will only have to call the conversion event with aa('conversion',{objectID: clickedObjectID}). The 2nd parameter is the same as above, it is the ID of the clicked result. This ID will be used to derive the queryID. Internally, the library keeps a store of associated click and search events. When a conversion event happens, it tries to correlate the conversion to a click event via the queryID. If no event is found, it assumes that the conversion event did not happen as a result of search.
            window.aa("conversion", {
                objectID: event.get("_id")
            });
        }
        const event_name = event.get("name");
        event.get("items").forEach(item => {
            if (window.ga) {
                // Provide product details in an productFieldObject.
                ga("ec:addProduct", {
                    // Product ID (string).
                    id: item.get("_id"),
                    // Product name (string).
                    name: `${event_name} (${event.get("venue_date_string")}) | ${item.get("name")}`,
                    // Product category (string).                // Product brand (string).
                    category: event.getIn(["category_id", "name"]),
                    // Product price (currency).
                    // Core returns item price as item price * count
                    // This was handled by a hack 3b6b7e8a90d95964a56a0606ba367f63d6087cde
                    // When payments success was rewritten in React the hack was lost
                    // This was causing incorrect numbers to be reported in Google Analytics
                    // This can't be fixed in core because Master Queue uses the same route
                    price: item.get("price") / item.get("count"),
                    // Product quantity (number).
                    quantity: item.get("count")
                });
            }
        });
        if (window.ga) {
            ga("ec:setAction", "purchase", {
                // Transaction details are provided in an actionFieldObject.
                id: transaction.get("transaction_id"),
                // (Required) Transaction id (string).
                shortcode: transaction.get("shortcode", ""),
                // Revenue (currency).
                revenue: transaction.get("amount")
            });
            ga("send", "event", "Ecommerce", "Transaction successful", {});
        }
        // GA4 analytics
        const itemsList = Array.from(event.get("items").map(item => {
            return {
                item_id: event.get("_id"),
                item_name: event.get("name"),
                price: item.get("price"),
                quantity: Number(item.get("count")),
                item_brand: event.getIn(["brand_id", "name"]),
                item_category: event.getIn(["category_id", "name"]),
                item_category2: `${event.get("name")} | ${item.get("name")}`,
            };
        }));
        const payload = {
            currency: "INR",
            value: transaction.get("amount"),
            transaction_id: transaction.get("transaction_id"),
            items: itemsList
        };
        if (isFromApp()) {
            triggerAppGAAnalytics({ event_name: GA_RECOMMENDED_EVENTS.PURCHASE, payload });
        }
        else {
            addGAEvent(GA_RECOMMENDED_EVENTS.PURCHASE, payload);
        }
        admitAdSuccess(transaction);
    });
};
const getFilteredItems = orderItems => {
    if (orderItems.length === 1) {
        return orderItems;
    }
    return orderItems.filter(orderItem => orderItem.configuration.is_ticket);
};
const sendGAPurchaseEvent = transaction => {
    try {
        const items = getFilteredItems(transaction.items);
        const itemsList = items.map(item => {
            const fulfillmentResponse = JSON.parse(item.fulfillment.fulfillment_response);
            const eventId = fulfillmentResponse.event_id;
            const ticketInfo = item.meta_data.seatInfo[0];
            return {
                item_id: eventId,
                item_name: item.meta_data.entityName,
                price: ticketInfo.pricePerSeat,
                quantity: ticketInfo.count,
                item_category2: `${item.meta_data.entityName} | ${ticketInfo.seatType}`
            };
        });
        addGAEvent(GA_RECOMMENDED_EVENTS.PURCHASE, {
            currency: "INR",
            value: transaction.grandtotal,
            transaction_id: transaction.id,
            items: itemsList
        });
    }
    catch (error) {
        console.error("Could not trigger H5 GA purchase event", error);
    }
};
const payments = (state = initialState, action) => {
    switch (action.type) {
        case RECEIVE_PAYMENT_SUCCESS_DATA: {
            const transaction = action.payload.getIn(["data", "transaction"]);
            onPaymentSuccess(transaction);
            return state.setIn(["transaction"], transaction);
        }
        case CLEAR_PAYMENT_SUCCESS_DATA: {
            return state.withMutations(s => s.delete("transaction"));
        }
        case SET_PRE_PAYMENT_DATA: {
            return state.setIn(["prePaymentData"], action.payload);
        }
        case CLEAR_PAYMENT_SUCCESS_DATA: {
            return state.withMutations(s => s.delete("prePaymentData"));
        }
        case REQUEST_PAYMENT_STATUS_DATA: {
            return state.setIn(["orderStatus", "dataRequested"], true);
        }
        case RECEIVE_PAYMENT_STATUS_DATA: {
            sendGAPurchaseEvent(action.payload);
            return state.setIn(["orderStatus", "dataReceived"], true).setIn(["orderStatus", "data"], fromJS(action.payload));
        }
        default:
            return state;
    }
};
export default payments;

import articlesData from "./articlesData";
import artists from "./artists";
import cartData from "./cartData";
import categories from "./categories";
import elpData from "./elpData";
import citiesData from "./citiesData";
import clubs from "./clubs";
import eventsData from "./eventsData";
import goPage from "./goPage";
import groups from "./groups";
import homepageData from "./homepageData";
import loginModal from "./loginModal";
import modals from "./modals";
import notifications from "./notifications";
import payments from "./payments";
import recommended from "./recommended";
import routes from "./routes";
import searchData from "./searchData";
import stashes from "./stashes";
import tickets from "./tickets";
import uiState from "./uiState";
import userData from "./userData";
import venues from "./venues";
import paytmWallet from "./paytmWallet";
import discountOffers from "./activeDiscountOffers";
import { islMatchScheduleEvents } from "./ISLData";
import { dynamicLandingEvents, newYearLandingEvents, newYear24Offers, mumbaiFestEvents, holi24Offers, iplLandingPage } from "./dynamicEventLandingData";
import { micrositeData } from './micrositeData';
import cityChanged from "./citySelectorChange";
const insiderApp = {
    articlesData,
    artists,
    cartData,
    categories,
    elpData,
    citiesData,
    clubs,
    eventsData,
    goPage,
    groups,
    homepageData,
    loginModal,
    modals,
    notifications,
    payments,
    recommended,
    routes,
    searchData,
    stashes,
    tickets,
    uiState,
    userData,
    venues,
    paytmWallet,
    discountOffers,
    islMatchScheduleEvents,
    dynamicLandingEvents,
    newYearLandingEvents,
    newYear24Offers,
    mumbaiFestEvents,
    holi24Offers,
    micrositeData,
    iplLandingPage,
    cityChanged
};
export { insiderApp };

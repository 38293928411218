import { fromJS, Map } from "immutable";
import { REQUEST_ARTICLE_DATA, RECEIVE_ARTICLE_DATA, CLEAR_ARTICLE_DATA } from "../actions/index";
const initialState = new Map();
const article = (state = initialState, action) => {
    switch (action.type) {
        case REQUEST_ARTICLE_DATA:
            return state.setIn([action.meta.slug, "dataRequested"], true);
        case RECEIVE_ARTICLE_DATA:
            const apiData = action.payload.get("data");
            const slug = action.meta.slug;
            const result = state.withMutations(s => {
                s
                    .setIn([apiData.get("slug"), "data"], apiData)
                    .setIn([slug, "dataRequested"], false)
                    .setIn([slug, "dataReceived"], true);
            });
            return result;
        case CLEAR_ARTICLE_DATA:
            return state.delete(action.payload);
        default:
            return state;
    }
};
export default article;

import { getPromoCodes } from "../helpers/paytmApiClient";
export const REQUEST_PAYTM_PROMO_CODE_DATA = "REQUEST_PAYTM_PROMO_CODE_DATA";
export const RECEIVE_PAYTM_PROMO_CODE_DATA = "RECEIVE_PAYTM_PROMO_CODE_DATA";
export const ERROR_PAYTM_PROMO_CODE_DATA = "ERROR_PAYTM_PROMO_CODE_DATA";
export function fetchPaytmPromoCodes(productIds) {
    return {
        types: [REQUEST_PAYTM_PROMO_CODE_DATA, RECEIVE_PAYTM_PROMO_CODE_DATA, ERROR_PAYTM_PROMO_CODE_DATA],
        promise: getPromoCodes(productIds)
    };
}

/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React from "react";
import InsiderLink from "../../containers/InsiderLinkWithSubscription";
import { useCart } from "../../hooks";
import { leftPad } from "../../helpers/misc";
import { addGAEvent, createCartItemParam, CURRENCY_CODE, GA_RECOMMENDED_EVENTS } from "../../helpers/constants/common";
import CartIcon from "../ui/icons/CartIcon";
import { colors } from "../../styles/common";
const Timer = css `
	width: 38px;
`;
const Container = (lastMin, isActive) => `
	display: flex;
	align-items: center;
	padding: 7px 15px;
	height: 38px;
	margin-right: 15px;
	background: ${isActive ? colors["tertiary-orange-light"] : "transparent"};
	border-radius: 100px;
	color:${lastMin ? colors.tertiaryOrange : "white"};
	font-weight: 600;
	font-size: 13px;
	line-height: 150%;
    cursor: pointer;
    border: 1px solid ${isActive ? colors.tertiaryOrange : colors["border-grey"]};
    transition: box-shadow .3s;

	@media screen and (max-width: 525px) {
		margin-right: 8px;
	}

    &:hover {
		border: 1px solid ${colors.tertiaryOrange};
	}
`;
const Cart = (props) => {
    const { isCartEmpty, numItems, payableCost, cartItemsData, fetchCart, clearCart, expires, clockSkew, location } = props;
    const timeRemaining = useCart(fetchCart, clearCart, expires, clockSkew);
    const checkoutUrl = "/buy/checkout";
    if (isCartEmpty) {
        return null;
    }
    if (numItems <= 0) {
        return null;
    }
    if (timeRemaining <= 0) {
        return null;
    }
    const handleCartClick = () => {
        addGAEvent(GA_RECOMMENDED_EVENTS.VIEW_CART, {
            currency: CURRENCY_CODE,
            value: payableCost,
            items: createCartItemParam(cartItemsData)
        });
    };
    const lastMinute = Math.floor(timeRemaining / 60) === 0;
    const currentPath = location.pathname + location.search;
    return (<InsiderLink id="CartIcon" css={css(Container(lastMinute, currentPath.includes(checkoutUrl)))} href={checkoutUrl} onClick={handleCartClick}>
			<CartIcon overideStyle={{ marginRight: "6px" }}/>
			<span css={Timer}>
				{leftPad(Math.floor(timeRemaining / 60))}:{leftPad(timeRemaining % 60)}
			</span>
		</InsiderLink>);
};
export default React.memo(Cart);

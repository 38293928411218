/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import { colors } from "../../styles/common";
import { GenericSearchIcon } from "../ui/icons/GenericSearchIcon";
const CityInputWrapper = `
	position: relative;	
`;
const CityInputStyles = `
	display: block;
	width: calc(100% - 16px);
	outline: none;
	padding: 12px 38px 12px 12px;
	border: 1px solid ${colors["faintGrey"]};
	background-color: ${colors["greyBackground"]};
	border-radius: 6px;
	box-sizing: border-box;
	margin: 8px 8px 0;
`;
const CityInputIcon = `
	position: absolute;
	right: 15px;
    top: 50%;
    transform: translateY(-50%);
`;
const CitySelectorSearch = (props) => {
    return (<div css={css(CityInputWrapper)}>
			<input css={css(CityInputStyles)} onChange={props.searchHandler} placeholder={props.placeholderContent} value={props.searchText}/>
			<GenericSearchIcon css={css(CityInputIcon)}/>
		</div>);
};
export default CitySelectorSearch;

import { syncHelperWithPaytmKeyValueAsPrimary } from "../index";
import { fetchUserData } from "../../apiClient";
const getDataForExpress = async () => {
    let userData = await fetchUserData();
    let updatedUserData;
    //retrieve customer id nested in the userData obj and attach it at root
    updatedUserData = userData.set("customerId", userData.getIn(["accounts", 0, "customer_id"]));
    //retrieve hashed encrypted paytm sso token, attach it to root
    const ssoTokenHash = userData.get("paytm_sso_token_hash");
    updatedUserData = userData.set("hashedEncryptedSsoToken", ssoTokenHash || "");
    return updatedUserData;
};
//sync helper for express
export const syncUserData = async (setDataForExpress, setUserData) => {
    await syncHelperWithPaytmKeyValueAsPrimary({
        setData: setDataForExpress,
        getData: getDataForExpress,
        primaryKey: "hashedEncryptedSsoToken",
        notifySyncDone: setUserData
    });
};

/** @jsx jsx */
import React from "react";
import { css, jsx } from "@emotion/core";
// components
import InsiderLink from "../../containers/InsiderLinkWithSubscription";
// helpers
import { generateSearchLink } from "../../helpers/linkGenerators";
import { DATA_REFS } from "../../helpers/constants/dataRefs";
import { NavItem } from "./AppBar/style";
import { SearchIcon } from "../ui/icons/SearchIcon/header";
import { sendClevertapEvent } from "../../helpers/misc/clevertap";
import { CLEVERTAP_ACTIONS } from "../../helpers/constants";
const sendClevertapEventFn = () => {
    sendClevertapEvent(CLEVERTAP_ACTIONS.SEARCH_CLICKED, {
        is_web: true
    });
};
const HideSearchIcon = `
	padding: 7px;	
	@media screen and (max-width: 576px) {
		display: none;
	}
`;
class AppBarSearchIcon extends React.Component {
    render() {
        const { setSearchTab, location } = this.props;
        const currentPath = location.pathname + location.search;
        const searchLink = generateSearchLink();
        const isActive = currentPath === searchLink;
        return (<InsiderLink onClick={() => {
                setSearchTab();
                sendClevertapEventFn(); // sending event to clevertap when search icon is clicked on website
            }} href={generateSearchLink()} dataRef={DATA_REFS.SEARCH_ICON_HEADER} css={css([NavItem(isActive ? "white" : undefined), HideSearchIcon])}>
				<SearchIcon />
			</InsiderLink>);
    }
}
export default AppBarSearchIcon;

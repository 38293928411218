import { Map } from "immutable";
import { combineReducers } from "redux-immutable";
// Set up redux connections
import { applyMiddleware, compose } from "redux";
import { insiderApp } from "./reducers/";
// import thunkMiddleware from "redux-thunk";
import promiseClientMiddleware from "./redux-middleware/promiseClientMiddleware";
import { createStore } from "redux-dynamic-modules";
import { getThunkExtension } from "redux-dynamic-modules-thunk";
const createInsiderStore = initialRootState => {
    // create redux store
    // Grab the state from a global injected into server-generated HTML
    const enhancer = compose(applyMiddleware(promiseClientMiddleware())
    // window.devToolsExtension ? window.devToolsExtension() : f => f
    );
    const store = createStore({
        initialState: initialRootState || new Map(),
        enhancers: [
            // applyMiddleware(promiseClientMiddleware()),
            // window.devToolsExtension ? window.devToolsExtension() : f => f
            enhancer
        ],
        extensions: [getThunkExtension()],
        advancedCombineReducers: combineReducers
    }, {
        id: "insiderApp",
        reducerMap: Object.assign({}, insiderApp),
        retained: true
    });
    // store = createStore(insiderApp, initialRootState, enhancer);
    // store = createStore(insiderApp, enhancer);
    return store;
};
export default createInsiderStore;

import { Map, List } from "immutable";
import { RECEIVE_CLUB_DATA, CLEAR_CLUB_DATA } from "../actions/index";
const initialState = new Map({
    list: new List([]),
    all: new List([]),
    club: new Map(),
    updates: new Map()
});
const clubs = (state = initialState, action) => {
    switch (action.type) {
        case RECEIVE_CLUB_DATA: {
            return state.setIn(["clubData"], action.payload.get("data"));
        }
        case CLEAR_CLUB_DATA: {
            return state.withMutations(s => s.delete("clubData"));
        }
        default:
            return state;
    }
};
export default clubs;

/** @jsx jsx */
import PropTypes from "prop-types";
import { css, jsx } from "@emotion/core";
import { capitalizeFirstLetter } from "../../helpers/constants";
import InsiderLink from "../../containers/InsiderLinkWithSubscription";
import { UserIcon as UserProfileIcon } from "../../components/ui/icons/UserIcon";
import { colors } from "../../styles/common";
const UserIconContainer = (isActive) => css `
	display: flex;
	align-items: center;
	height: 38px;
	width: 40px;
	margin-right: 15px;
	background: ${isActive ? colors["hover-primary-blue"] : "transparent"};
	border-radius: 100px;
	color: white;
	font-weight: 600;
	font-size: 13px;
	line-height: 150%;
	cursor: pointer;
	border: 1px solid ${isActive ? "white" : colors["border-grey"]};
	transition: box-shadow 0.3s;
	justify-content: center;

	&:hover {
		border: 1px solid white;
	}

	@media screen and (max-width: 768px) {
		display: none;
	}
`;
const getUserInitials = userInfo => `${capitalizeFirstLetter(userInfo.get("first_name", "")).charAt(0)}${capitalizeFirstLetter(userInfo.get("last_name", "")).charAt(0)}`;
const UserIcon = (props) => {
    const { location } = props;
    const currentPath = location.pathname + location.search;
    if (!props.userInfo || !props.userInfo.size) {
        // when user is not logged in, show default icon and i'm linking href to users/login section
        return (<a {...(props.isSPA && { onClick: props.login })} css={css([UserIconContainer(currentPath === "/users/me"), `display: flex;`])} rel="nofollow" href="/users/login">
				<UserProfileIcon />
			</a>);
    }
    // when user is logged in
    if (props.userInfo.get("first_name") || props.userInfo.get("last_name")) {
        return (<InsiderLink onClick={props.login} css={css([UserIconContainer(currentPath === "/users/me"), `display: flex;`])} rel="nofollow" href="/users/me">
				<span>{getUserInitials(props.userInfo)}</span>
			</InsiderLink>);
    }
    return (<InsiderLink onClick={props.login} css={css([UserIconContainer(currentPath === "/users/me"), `display: flex;`])} rel="nofollow" href="/users/me">
			<UserProfileIcon />
		</InsiderLink>);
};
UserIcon.propTypes = {
    userInfo: PropTypes.object
};
export default UserIcon;

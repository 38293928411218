import { fromJS, Map } from "immutable";
import { CLEAR_MODEL_DATA_FOR_TAG, RECEIVE_MODEL_DATA_FOR_TAG, REQUEST_MODEL_DATA_FOR_TAG, ERROR_MODEL_DATA_FOR_TAG } from "../actions/index";
const initialState = new Map();
const goPage = (state = initialState, action) => {
    switch (action.type) {
        case REQUEST_MODEL_DATA_FOR_TAG:
            return state.setIn([action.meta, "loading"], true);
        case RECEIVE_MODEL_DATA_FOR_TAG:
            const apiData = action.payload.get("data");
            const slug = apiData.getIn(["meta", "0", "slug"]);
            return state.withMutations(s => s
                .setIn([slug, "data"], apiData.get("list"))
                .setIn([slug, "title"], apiData.getIn(["meta", "0", "name"]))
                .setIn([slug, "description"], apiData.getIn(["meta", "0", "description"]))
                .setIn([slug, "loading"], false));
        case ERROR_MODEL_DATA_FOR_TAG:
            return state.setIn([action.meta, "loading"], false);
        case CLEAR_MODEL_DATA_FOR_TAG:
            return initialState;
        default:
            return state;
    }
};
export default goPage;

export const loginCallback = (userData, setUserLoginData, setLoginError, resetModalType, notify, clearNotifications, redirectUrl) => {
    const redirectUrlExists = redirectUrl && redirectUrl.length > 0;
    const message = `Hello ${userData.get("first_name") ||
        userData.get("last_name") ||
        userData.get("handle") ||
        userData.get("email", "")}`;
    if (notify) {
        notify([
            {
                message,
                autoDismiss: 3,
                level: "success",
                onRemove: () => {
                    if (clearNotifications) {
                        clearNotifications();
                    }
                },
                position: "bl"
            }
        ]);
    }
    setUserLoginData(userData);
    document.documentElement.classList.remove("no-scroll");
    setLoginError("");
    resetModalType();
    if (redirectUrlExists) {
        window.location.href = redirectUrl;
    }
};

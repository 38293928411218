import { validateMediaQuery } from "../../../helpers/styleHelpers";
import { addMediaType, mediaFeatures } from "../../../styles/common";
import { generateCitySelectorLink, } from "../../../helpers/linkGenerators";
import { browserHistory } from "react-router";
const shouldGoToMobileCitySelector = (props) => {
    const { isSelectedCityInferred, isSPA, shouldShowCitySelector } = props;
    const isMobile = validateMediaQuery(`${addMediaType} ${mediaFeatures["for-mobile"]}`);
    const isUserSelectingCity = window && window.location.pathname.includes(generateCitySelectorLink());
    if (!isSelectedCityInferred && isSPA && isMobile && shouldShowCitySelector && !isUserSelectingCity) {
        return true;
    }
};
export const goToMobileCitySelector = (props) => {
    if (shouldGoToMobileCitySelector(props)) {
        browserHistory.push(generateCitySelectorLink());
    }
};

import { List, Map } from "immutable";
import { pluralize } from "../helpers/constants";
export const normalizePartnerEvents = (events, i) => {
    // use false value to flag ComingSoon component
    if (!events.size)
        return {
            upcomingEvents: new List([]),
            expiredEvents: new List([])
        };
    const Events = events.map(event => {
        return new Map({
            _id: event.get("_id"),
            category_id: event.get("category_id"),
            city: event.get("city"),
            event_state: event.get("event_state"),
            horizontal_cover_image: event.get("horizontal_cover_image"),
            is_rsvp: event.get("is_rsvp"),
            min_show_start_time: event.getIn(["events", "0", "venues", "0", "shows", "0", "start_utc_timestamp"]) || 0,
            model: "event",
            name: event.get("name"),
            price_display_string: event.get("price_display_string"),
            slug: event.get("slug"),
            tags: event.get("tags"),
            type: event.get("type"),
            venue_date_string: event.getIn(["events", "0", "venues", "0", "date_string"]),
            venue_name: event.getIn(["events", "0", "venues", "0", "name"]),
            vertical_cover_image: event.get("vertical_cover_image"),
            event_type: event.get("event_type"),
            popularity_score: event.get("popularity_score")
        });
    });
    const upcomingEvents = Events.filter(event => {
        return event.get("min_show_start_time") > 0;
    });
    const expiredEvents = Events.filter(event => {
        return event.get("min_show_start_time") === 0;
    });
    return {
        upcomingEvents,
        expiredEvents
    };
};
export const normalizePartnerUpdates = articles => {
    return articles.map((article, idx) => {
        return new Map({
            category: article.get("category"),
            category_id: article.get("category_id"),
            horizontal_cover_image: article.get("horizontalCoverImage") || article.get("horizontalImageCover"),
            model: "article",
            slug: article.get("slug"),
            title: article.get("title")
        });
    });
};
export const normalize = artistsData => {
    return artistsData.map(a => {
        const eventCount = a.get("active_events") || a.get("activeEvents");
        let subTextString = pluralize(eventCount, "event");
        return new Map({
            _id: a.get("_id", ""),
            description: a.get("description", ""),
            name: a.get("name", ""),
            slug: a.get("slug"),
            src: a.get("share_image", "https://placeholdit.imgix.net/~text?txtsize=33&txt=350%C3%97150&w=450&h=246"),
            subtext: subTextString,
            type: "artist"
        });
    });
};
export const normalizePartner = a => {
    const { upcomingEvents, expiredEvents } = normalizePartnerEvents(a.get("list"));
    const about = a.getIn(["meta", "0", "description"]);
    const slug = a.getIn(["meta", "0", "slug"]);
    const name = a.getIn(["meta", "0", "name"]);
    const _id = a.getIn(["meta", "0", "_id"]);
    const share_image = a.getIn(["meta", "0", "share_image"]);
    const seo_title = a.getIn(["meta", "0", "display_details", "seo_title"]) || name;
    const seo_description = a.getIn(["meta", "0", "display_details", "seo_description"]) || about;
    const seo_keywords = a.getIn(["meta", "0", "display_details", "seo_keywords"]) || name;
    // these come from display_details,data unavailable for many artists/venues
    let hero = a.getIn(["meta", "0", "display_details"]);
    if (hero)
        hero = hero.delete("testimonials");
    const gallery = a.getIn(["meta", "0", "display_details", "gallery"]) || new List([]);
    const videos = a.getIn(["meta", "0", "display_details", "videos"]) || new List([]);
    const venue = a.getIn(["meta", "0", "display_details", "venue_id"]) || new List([]);
    //const testimonials = a.getIn(['meta','0','display_details','testimonials']) || new List([]);
    return new Map({
        _id,
        name,
        slug,
        share_image,
        seo_title,
        seo_description,
        hero,
        tabs: new Map({
            upcomingEvents,
            about,
            gallery,
            videos,
            venue,
            expiredEvents
        })
    });
};

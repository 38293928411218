import { fromJS, Map, List, OrderedMap } from "immutable";
import { 
// api actions
RECEIVE_GOOUT_ELP_DATA, REQUEST_GOOUT_ELP_DATA, REQUEST_DYNAMIC_SECTION_DATA, RECEIVE_DYNAMIC_SECTION_DATA, ERROR_DYNAMIC_SECTION_DATA, 
// filter & sort actions
APPLY_GOOUT_SORT_FILTERS } from "../actions/index";
import { RECEIVE_MONUMENTS_DATA, REQUEST_MONUMENTS_DATA } from "../actions";
const sectionStructure = fromJS({
    monumentsDataRequested: false,
    monumentsDataReceived: false,
    dataRequested: false,
    dataReceived: false,
    filters: {},
    sorters: [],
    list: {
        masterList: {},
        groupwiseList: {},
        categorywiseList: {},
        typewiseList: {}
    },
    filterBy: {},
    sortBy: {},
    text: {},
    banners: [],
    digitalEventsCarousel: {
        description: null,
        digitalEventsCarouselItems: []
    }
});
// we have go-out data to start with
// and can add dynamic section on go
// right now its added for dynamicList pages
const initialState = fromJS({
    monumentsDataRequested: false,
    monumentsDataReceived: false,
    "go-out": {
        dataRequested: false,
        dataReceived: false,
        filters: {},
        sorters: [],
        list: {
            masterList: {},
            groupwiseList: {},
            categorywiseList: {},
            typewiseList: {}
        },
        filterBy: {},
        sortBy: {},
        text: {},
        banners: [],
        digitalEventsCarousel: {
            description: null,
            digitalEventsCarouselItems: []
        }
    }
});
// this need to be discussed a bit
const defaultSortBy = sorters => sorters.map(() => new Map({ key: "min_show_start_time", type: "asc" }));
const getCategoryCount = (categoryName, list) => {
    return list.get(categoryName).size;
};
// make genre filters from data and add to filters
const addGenreFilter = (list, filters, categoryList) => {
    const eventsByGroup = list.groupBy((v, slug) => v.getIn(["group_id", "name"]));
    return eventsByGroup.map((items, key) => {
        let genres = items
            .map(item => {
            return item.getIn(["category_id", "name"]);
        })
            .toSet() //getting unique filters
            .map(genre => {
            return new Map({
                key: genre,
                display: genre,
                count: parseInt(getCategoryCount(genre, categoryList))
            });
        })
            .toList()
            .sort((a, b) => b.get("count") - a.get("count"));
        return filters.get(key).set("genre", genres);
    });
};
export const extractData = apiData => {
    // popular are sorted descending
    return {
        groupedListData: apiData.get("list"),
        sorters: apiData.get("sorters"),
        // add genre filters to the provided filters
        filters: addGenreFilter(apiData.getIn(["list", "masterList"]), apiData.get("filters"), apiData.getIn(["list", "categorywiseList"])),
        text: apiData.get("text"),
        dates: apiData.get("dates"),
        banners: apiData.get("banners"),
        digitalEventsCarousel: new Map({
            description: apiData.get("digital_event_groups_description"),
            digitalEventsCarouselItems: apiData.get("digital_event_groups")
        }),
        slugDetails: apiData.get("slug_details")
    };
};
const elpData = (state = initialState, action) => {
    switch (action.type) {
        case REQUEST_GOOUT_ELP_DATA: {
            return state.setIn(["go-out", "dataRequested"], true);
        }
        case RECEIVE_GOOUT_ELP_DATA: {
            const apiData = action.payload;
            const { groupedListData, sorters, filters, text, dates, banners, digitalEventsCarousel } = extractData(apiData);
            // if sortBy exists let it be, else do a default sort by date, asc
            const sortBy = state.getIn(["go-out", "sortBy"]).size
                ? state.getIn(["go-out", "sortBy"])
                : defaultSortBy(sorters);
            return state.withMutations(m => m
                .setIn(["go-out", "dataReceived"], true)
                .setIn(["go-out", "dataRequested"], false)
                .mergeDeepIn(["go-out", "list"], groupedListData)
                .setIn(["go-out", "filters"], filters)
                .setIn(["go-out", "sorters"], sorters)
                .mergeDeepIn(["go-out", "text"], text)
                .setIn(["go-out", "sortBy"], sortBy)
                .setIn(["go-out", "dates"], dates)
                .setIn(["go-out", "banners"], banners)
                .setIn(["go-out", "digitalEventsCarousel"], digitalEventsCarousel));
        }
        case APPLY_GOOUT_SORT_FILTERS: {
            if (action.componentType === "group") {
                return state
                    .setIn(["go-out", "sortBy", action.tab], action.sort)
                    .setIn(["go-out", "filterBy", action.tab], action.filters);
            }
            return state;
        }
        // can't handle section data in GOOUT_DATA because its used in so many other places
        case REQUEST_DYNAMIC_SECTION_DATA: {
            const section = action.meta;
            return state.setIn([section, "dataRequested"], true);
        }
        case RECEIVE_DYNAMIC_SECTION_DATA: {
            const apiData = action.payload;
            const { groupedListData, text, dates, banners, slugDetails } = extractData(apiData);
            const section = action.meta;
            return state.withMutations(m => m
                .setIn([section, "dataReceived"], true)
                .setIn([section, "dataRequested"], false)
                .mergeDeepIn([section, "list"], groupedListData)
                .mergeDeepIn([section, "text"], text)
                .setIn([section, "dates"], dates)
                .setIn([section, "banners"], banners)
                .setIn([section, "slugDetails"], slugDetails)
                .setIn([section, "filterBy"], new Map())
                .setIn([section, "sortBy"], new Map()));
        }
        case ERROR_DYNAMIC_SECTION_DATA: {
            const section = action.meta;
            return state.withMutations(m => m.setIn([section, "dataReceived"], false).setIn([section, "dataRequested"], false));
        }
        case REQUEST_MONUMENTS_DATA: {
            return state.set("monumentsDataRequested", true);
        }
        case RECEIVE_MONUMENTS_DATA: {
            const apiData = action.payload;
            const monumentsMasterList = apiData.getIn(["list", "masterList"]);
            const monumentsGroupWiseList = apiData.getIn(["list", "groupwiseList"]);
            const monumentsText = apiData.getIn(["text", "Monuments"]);
            return state.withMutations(s => s
                .set("monumentsDataReceived", true)
                .mergeDeepIn(["go-out", "list", "masterList"], monumentsMasterList)
                .mergeDeepIn(["go-out", "list", "groupwiseList"], monumentsGroupWiseList)
                .mergeDeepIn(["go-out", "text"], monumentsText));
        }
        default:
            return state;
    }
};
export default elpData;

import { Map } from "immutable";
import { CLEAR_LOGIN_MODAL_DATA, SET_MODAL_LOGIN_ERROR, SET_MODAL_REDIRECT_URL, SET_MODAL_SCREEN, SET_MODAL_SOURCE, SET_EMAIL } from "../actions/index";
const initialState = new Map({
    screen: "initialScreen",
    verifiedEmail: "",
    loginError: ""
});
const loginModal = (state = initialState, action) => {
    switch (action.type) {
        case CLEAR_LOGIN_MODAL_DATA: {
            return initialState;
        }
        case SET_MODAL_LOGIN_ERROR: {
            return state.set("loginError", action.payload);
        }
        case SET_MODAL_REDIRECT_URL: {
            return state.withMutations(s => s.set("redirectUrl", action.payload).set("loginError", ""));
        }
        case SET_MODAL_SCREEN: {
            return state.withMutations(s => s.set("screen", action.payload).set("loginError", ""));
        }
        case SET_MODAL_SOURCE: {
            return state.withMutations(s => s.set("source", action.payload).set("loginError", ""));
        }
        case SET_EMAIL: {
            return state.set("email", action.payload);
        }
        default:
            return state;
    }
};
export default loginModal;

import { Map, List, fromJS } from "immutable";
import { RECEIVE_STASHES_DATA, CLEAR_STASHES_DATA, REQUEST_STASHBOOK_DATA, RECEIVE_STASHBOOK_DATA } from "../actions/index";
const initialState = fromJS({
    stashes: [],
    stashBook: {
        dataRequested: false,
        dataReceived: false,
        data: []
    }
});
const stashes = (state = initialState, action) => {
    switch (action.type) {
        case RECEIVE_STASHES_DATA: {
            return state.set("stashes", action.payload.getIn(["data", "stashes"]));
        }
        case CLEAR_STASHES_DATA: {
            return state.set("stashes", []);
        }
        case REQUEST_STASHBOOK_DATA: {
            return state.setIn(["stashBook", "dataRequested"], true);
        }
        case RECEIVE_STASHBOOK_DATA: {
            const stashBookData = action.payload.get("result", new List([]));
            return state.withMutations(m => m
                .setIn(["stashBook", "dataReceived"], true)
                .setIn(["stashBook", "data"], stashBookData));
        }
        default:
            return state;
    }
};
export default stashes;

const Base64 = require("crypto-js/enc-base64");
const HmacSHA1 = require("crypto-js/hmac-sha1");
function _pad(val) {
    if (parseInt(val, 10) < 10) {
        val = "0" + val;
    }
    return val;
}
function _rfc1123(date) {
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    return (days[date.getUTCDay()] +
        ", " +
        _pad(date.getUTCDate()) +
        " " +
        months[date.getUTCMonth()] +
        " " +
        date.getUTCFullYear() +
        " " +
        _pad(date.getUTCHours()) +
        ":" +
        _pad(date.getUTCMinutes()) +
        ":" +
        _pad(date.getUTCSeconds()) +
        " GMT");
}
module.exports = {
    formatDate: _rfc1123,
    sign: function sign(date, apiKey, secret) {
        if (date === undefined || date === "") {
            throw new Error("x-date was not in the request");
        }
        const headers = ["x-date"].join(" "), algorithm = "hmac-sha1";
        const stringToSign = "x-date: " + _rfc1123(date);
        const hmac = HmacSHA1(stringToSign, secret, { asString: true });
        const signature = Base64.stringify(hmac);
        return `Hmac username="${apiKey}",algorithm="${algorithm}",headers="${headers}",signature="${signature}"`;
    }
};

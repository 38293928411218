/*
We have a 3 tier data strategy
1. All reads at the application level should only happen from redux
2. The redux read store is synchronous so easy to reason about
3. To populate the synchronous store, we need a source of trust
4. There can be 2 sources of trust - Paytm and Insider systems
5. We first copy over data from the primary source of trust into the secondary
6. We need to do this because we cannot guarantee that the primary source is being written by only us
7. Therefore we need a snapshotted copy of the primary data that we can use guaranteeing that we are the only ones writing to it
8. Since the secondary source is async, and that causes overhead in React to deal with simple reads, we have a synchronous read store (Redux) on top of the secondary
9. The secondary store is used for write ahead queries, with reads happening from redux
 */
import { getUserData, login as paytmLogin, createMD5hash, isPaytmTokenValid } from "../h5";
import { sendClevertapEvent } from "../misc";
import { CLEVERTAP_ACTIONS } from "../constants";
import { fromJS } from "immutable";
export const setDataForPaytmSession = async () => {
    sendClevertapEvent(CLEVERTAP_ACTIONS.LIVE_EVENT_OPEN_PAYTM_LOGIN_MODAL);
    const isPaytmLoginSuccess = await paytmLogin();
    if (isPaytmLoginSuccess) {
        return Promise.resolve();
    }
    else {
        return Promise.reject({ err: "User did not complete paytm login" });
    }
};
const getUserDataForPaytm = async () => {
    const userData = await getUserData();
    if (userData) {
        userData["email"] = userData["emailId"];
        const encryptedSsoToken = userData["encryptedSsoToken"];
        userData["hashedEncryptedSsoToken"] = encryptedSsoToken && createMD5hash(encryptedSsoToken);
    }
    return fromJS(userData);
};
export const getSyncHelperWithPrimarySource = (primarySource) => {
    console.log("primarySource", primarySource);
    return async (secondarySource) => {
        console.log("secondarySource", primarySource);
        try {
            let [primaryInitData, secondaryData] = await Promise.all([primarySource.getData(), secondarySource.getData()]);
            // if no primary key in primary data, call setPrimary
            if (!primaryInitData || !primaryInitData.get(primarySource.primaryKey)) {
                try {
                    console.log(`setting up primary source data`);
                    await primarySource.setData();
                }
                catch (e) {
                    console.error(`primarySource.setData failed because of `, e);
                    return Promise.reject(e);
                }
                console.log(`fetching data from primary source`);
                primaryInitData = await primarySource.getData();
            }
            console.log(`primary key in primary data: ${primaryInitData.get(primarySource.primaryKey)}`);
            console.log(`primary key in secondary data: ${secondaryData.get(primarySource.primaryKey)}`);
            const isSessionValid = await isPaytmTokenValid();
            // if the primary data does not match with secondary data...call setSecondary
            if (primaryInitData.get(primarySource.primaryKey) !== secondaryData.get(secondarySource.primaryKey) ||
                isSessionValid === false) {
                try {
                    console.log("is session valid", isSessionValid);
                    console.log(`Primary key mismatch => updating sources`);
                    await secondarySource.setData(primaryInitData);
                    //get the latest copy of secondary data
                    secondaryData = await secondarySource.getData();
                }
                catch (e) {
                    console.error(`secondarySource.setData failed because of `, e);
                    return Promise.reject(e);
                }
            }
            // if the primary source and secondary source are in sync...sync data to redux
            // we merge this data since we dont get everything from primaryData,
            // we set a precedence for primaryInitData
            try {
                console.log(`secondarySource.notifySyncDone(mergedData);`);
                const mergedData = secondaryData.mergeDeep(primaryInitData);
                await secondarySource.notifySyncDone(mergedData);
                return;
            }
            catch (e) {
                console.error(`secondarySource.notifySyncDone failed because of ${e}`);
                return Promise.reject(e);
            }
        }
        catch (e) {
            console.error(e);
            return Promise.reject(e);
        }
    };
};
export const syncHelperWithPaytmKeyValueAsPrimary = getSyncHelperWithPrimarySource({
    setData: setDataForPaytmSession,
    getData: getUserDataForPaytm,
    primaryKey: "hashedEncryptedSsoToken"
});

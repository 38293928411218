import { Map, List } from "immutable";
import { RECEIVE_TOAST_NOTTIFICATIONS, CLEAR_TOAST_NOTIFICATIONS, RECEIVE_TOAST_MESSAGES } from "../actions/index";
const initialState = new Map({
    alerts: new List([]),
    messages: new List([])
});
const userData = (state = initialState, action) => {
    switch (action.type) {
        case RECEIVE_TOAST_NOTTIFICATIONS: {
            return state.set("alerts", new List(action.payload));
        }
        case CLEAR_TOAST_NOTIFICATIONS: {
            return initialState;
        }
        case RECEIVE_TOAST_MESSAGES: {
            return state.set("messages", new List(action.payload));
        }
        default:
            return state;
    }
};
export default userData;

import { fromJS } from "immutable";
import { getEncryptedSsoToken } from "./auth";
import { PAYTM_PROMO_CODE_URL } from "./constants";
export const fetchPaytmData = function (endpoint, resource, auth) {
    const API_OPTIONS = {
        headers: {
            "Accept-Encoding": "gzip"
        }
    };
    if (auth) {
        let encryptedSsoToken = getEncryptedSsoToken();
        if (encryptedSsoToken) {
            API_OPTIONS.headers["encr_sso_token"] = encryptedSsoToken;
        }
    }
    let res;
    return fetch(`${endpoint}/${resource}`, API_OPTIONS)
        .then(response => {
        res = response;
        if (response.status < 400) {
            return response;
        }
        else if (response.status === 404) {
            throw { name: "NotFoundError" };
        }
        const meta = {};
        // node-fetch response.headers is not iterable
        if (window._errs) {
            for (const [header, value] of response.headers) {
                meta[header] = value;
            }
            window._errs.meta = meta;
        }
        throw new Error(`Bad status code ${response.status} ${endpoint}/${resource}`);
    })
        .then(response => response.json())
        .then(fromJS)
        .catch(e => {
        if (!window._errs) {
            // _errs will only be available on the client
            throw e;
        }
        if (res) {
            res
                .text()
                .then((body) => {
                window._errs.meta && (window._errs.meta.responseBody = body);
                window._errs.push(e);
            })
                .catch(() => {
                // cached error reponses might come from s3 and might be html
                window._errs.meta.parseError = "error: could not parse response body";
                window._errs.push(e);
            });
        }
        else {
            window._errs.meta && (window._errs.meta.responseError = "error: undefined response");
            window._errs.push(e);
        }
        throw e;
    });
};
export const getPromoCodes = (productIds) => {
    const promises = productIds.map(productId => {
        return fetchPaytmData(PAYTM_PROMO_CODE_URL, `papi/v2/promosearch/category/75975/offers?product_id=${productId}&tnc=true&limit=10`, true);
    });
    return Promise.all(promises);
};

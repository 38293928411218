import React from "react";
import Loader from "./Loader";
import ErrorComponent from "./ErrorComponent";
const LoadableLoader = props => {
    if (props.isLoading) {
        // make a timeout componet here??
        if (props.timedOut)
            return <div>Loader timedout </div>;
        else if (props.pastDelay)
            return <Loader />;
        else
            return null;
    }
    else if (props.error) {
        return <ErrorComponent />;
    }
    else {
        return null;
    }
};
export default LoadableLoader;

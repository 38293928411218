import { Map, fromJS } from "immutable";
import { SET_MODAL_TYPE, RESET_MODAL_TYPE } from "../actions/index";
const initialState = new Map({});
const modals = (state = initialState, action) => {
    switch (action.type) {
        case SET_MODAL_TYPE: {
            return fromJS(action.payload);
        }
        case RESET_MODAL_TYPE: {
            return initialState;
        }
        default:
            return state;
    }
};
export default modals;

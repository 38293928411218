import { fromJS, Map } from "immutable";
import { REQUEST_MICROSITE_LANDING_PAGE_SCHEDULE_DATA, RECEIVE_MICROSITE_LANDING_PAGE_SCHEDULE_DATA, ERROR_MICROSITE_LANDING_PAGE_SCHEDULE_DATA, REQUEST_MICROSITE_LANDING_PAGE_DATA, RECEIVE_MICROSITE_LANDING_PAGE_DATA, ERROR_MICROSITE_LANDING_PAGE_DATA, } from "../actions/index";
const initialState = {
    pageData: {
        data: [],
        dataRequested: false,
        dataReceived: false,
        errorRecieved: false,
    },
    scheduleData: {
        data: [],
        dataRequested: false,
        dataReceived: false,
        errorRecieved: false,
    },
};
export const micrositeData = (state = initialState, action) => {
    const apiData = action.payload;
    switch (action.type) {
        case REQUEST_MICROSITE_LANDING_PAGE_SCHEDULE_DATA:
            return Object.assign(Object.assign({}, state), { scheduleData: Object.assign(Object.assign({}, state.scheduleData), { dataRequested: true, newping: true }) });
        case RECEIVE_MICROSITE_LANDING_PAGE_SCHEDULE_DATA:
            return Object.assign(Object.assign({}, state), { scheduleData: { data: apiData, dataRequested: false, dataReceived: true, errorRecieved: false } });
        case ERROR_MICROSITE_LANDING_PAGE_SCHEDULE_DATA:
            return Object.assign(Object.assign({}, state), { scheduleData: Object.assign(Object.assign({}, state.scheduleData), { dataRequested: false, dataReceived: false, errorRecieved: true }) });
        case REQUEST_MICROSITE_LANDING_PAGE_DATA:
            return Object.assign(Object.assign({}, state), { pageData: Object.assign(Object.assign({}, state.pageData), { dataRequested: true, newping: true }) });
        case RECEIVE_MICROSITE_LANDING_PAGE_DATA:
            return Object.assign(Object.assign({}, state), { pageData: { data: apiData, dataRequested: false, dataReceived: true, errorRecieved: false } });
        case ERROR_MICROSITE_LANDING_PAGE_DATA:
            return Object.assign(Object.assign({}, state), { pageData: Object.assign(Object.assign({}, state.pageData), { dataRequested: false, dataReceived: false, errorRecieved: true }) });
        default:
            return state;
    }
};

// ACTION TYPES
// paytm wallet link modal data
export const SET_PAYTM_WALLET_BALANCE = "SET_PAYTM_WALLET_BALANCE";
export const SET_PAYTM_WALLET_DATA = "SET_PAYTM_WALLET_DATA";
export const SET_PAYTM_WALLET_MODAL_ERROR = "SET_PAYTM_WALLET_MODAL_ERROR";
export const SET_PAYTM_WALLET_MODAL_SCREEN = "SET_PAYTM_WALLET_MODAL_SCREEN";
export const SET_PAYTM_WALLET_NUMBER = "SET_PAYTM_WALLET_NUMBER";
export const SET_PAYTM_WALLET_STATUS = "SET_PAYTM_WALLET_STATUS";
export const SET_PRE_PAYMENT_DATA = "SET_PRE_PAYMENT_DATA";
export const CLEAR_PRE_PAYMENT_DATA = "CLEAR_PRE_PAYMENT_DATA";
// END OF ACTION TYPES
// paytm wallet
export function setPaytmWalletBalance(payload) {
    return {
        type: SET_PAYTM_WALLET_BALANCE,
        payload
    };
}
export function setPaytmWalletData(payload) {
    return {
        type: SET_PAYTM_WALLET_DATA,
        payload
    };
}
export function setPaytmWalletModalError(payload) {
    return {
        type: SET_PAYTM_WALLET_MODAL_ERROR,
        payload
    };
}
export function setPaytmWalletModalScreen(payload) {
    return {
        type: SET_PAYTM_WALLET_MODAL_SCREEN,
        payload
    };
}
export function setPaytmWalletNumber(payload) {
    return {
        type: SET_PAYTM_WALLET_NUMBER,
        payload
    };
}
export function setPaytmWalletStatus(payload) {
    return {
        type: SET_PAYTM_WALLET_STATUS,
        payload
    };
}
export function setPrePaymentData(payload) {
    return {
        type: SET_PRE_PAYMENT_DATA,
        payload
    };
}
export function clearPrePaymentData() {
    return {
        type: CLEAR_PRE_PAYMENT_DATA
    };
}

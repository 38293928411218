import React from "react";
import { connect } from "react-redux";
// components
import AppBarSearchIcon from "../components/header/AppBarSearchIcon";
// containers
import SearchViewTogglerWithSubscription from "./SearchViewTogglerWithSubscription";
// actions + helpers
import { setSearchTab } from "../actions/index";
import { withRouter } from "react-router";
const mapDispatchToProps = dispatch => ({
    setSearchTab: () => dispatch(setSearchTab(0))
});
export default withRouter(connect(null, mapDispatchToProps)(AppBarSearchIcon));

import { fromJS, Map, List, OrderedMap } from "immutable";
import { 
// api actions
RECEIVE_HOMEPAGE_DATA, RECEIVE_GOOUT_DATA, REQUEST_GOOUT_DATA, ERROR_GOOUT_DATA, RECEIVE_EVENT_FAV, REQUEST_EVENT_FAV, 
// unmount actions
CLEAR_HOMEPAGE_DATA, CLEAR_GOOUT_DATA, CLEAR_STAYIN_DATA, RECEIVE_POPULAR_DATA, SITE_DISPLAY_MODE_CHANGED } from "../actions/index";
const sectionStructure = fromJS({
    dataRequested: false,
    dataReceived: false,
    tabList: [],
    featured: [],
    list: {
        masterList: {},
        groupwiseList: {},
        categorywiseList: {},
        typewiseList: {}
    },
    picks: {},
    popular: [],
    text: {},
    banners: [],
    digitalEventsCarousel: {
        description: null,
        digitalEventsCarouselItems: []
    }
});
// we have go-out data to start with
// and can add dynamic section on go
// right now its added for dynamicList pages
const initialState = fromJS({
    homepage: {
        featured: [],
        list: []
    },
    "go-out": {
        dataRequested: false,
        dataReceived: false,
        tabList: [],
        featured: [],
        list: {
            masterList: {},
            groupwiseList: {},
            categorywiseList: {},
            typewiseList: {}
        },
        picks: {},
        popular: [],
        text: {},
        banners: [],
        digitalEventsCarousel: {
            description: null,
            digitalEventsCarouselItems: []
        }
    },
    "stay-in-carousel": []
});
// this need to be discussed a bit
const defaultSortBy = sorters => sorters.map(() => new Map({ key: "min_show_start_time", type: "asc" }));
const getCategoryCount = (categoryName, list) => {
    return list.get(categoryName).size;
};
// make genre filters from data and add to filters
const addGenreFilter = (list, filters, categoryList) => {
    const eventsByGroup = list.groupBy((v, slug) => v.getIn(["group_id", "name"]));
    return eventsByGroup.map((items, key) => {
        let genres = items
            .map(item => {
            return item.getIn(["category_id", "name"]);
        })
            .toSet() //getting unique filters
            .map(genre => {
            return new Map({
                key: genre,
                display: genre,
                count: parseInt(getCategoryCount(genre, categoryList))
            });
        })
            .toList()
            .sort((a, b) => b.get("count") - a.get("count"));
        return filters.get(key).set("genre", genres);
    });
};
let getPopular = function (apiData) {
    // normalize popularity score for populat
    return apiData
        .get("popular", new List())
        .map(v => v.withMutations(m => m.set("normalized_popularity_score", m.get("popularity_score"))))
        .take(10); // only have 10 events in popular
};
export const extractData = apiData => {
    // popular are sorted descending
    return {
        featuredData: apiData.get("featured"),
        groupedListData: apiData.get("list"),
        picks: apiData.get("picks"),
        tabList: apiData.get("groups"),
        text: apiData.get("text"),
        dates: apiData.get("dates"),
        banners: apiData.get("banners"),
        popular: getPopular(apiData),
        digitalEventsCarousel: new Map({
            description: apiData.get("digital_event_groups_description"),
            digitalEventsCarouselItems: apiData.get("homepage_digital_event_groups")
        }),
        slugDetails: apiData.get("slug_details"),
        categories: apiData.get("categories")
    };
};
// clear just api data related to stay-in/go-out not sortBy/FilterBy which are user ui actions
const clearData = (state, section) => {
    return state.withMutations(m => m
        .setIn([section, "featured"], new List([]))
        .setIn([section, "popular"], new List([]))
        .setIn([section, "tabList"], new List([]))
        .setIn([section, "list"], new Map({}))
        .setIn([section, "picks"], new Map({})));
};
// add a new section
const addSection = (state, section) => {
    return state.set(section, sectionStructure);
};
const homepageData = (state = initialState, action) => {
    switch (action.type) {
        case RECEIVE_HOMEPAGE_DATA: {
            const apiData = action.payload;
            const featuredData = apiData.get("featured");
            return state.setIn(["homepage", "featured"], featuredData);
        }
        case CLEAR_HOMEPAGE_DATA: {
            // we go back to initial state
            // this is to prevent code duplication where we again
            // specify shape of data here
            return state.set("homepage", initialState.get("homepage"));
        }
        case REQUEST_GOOUT_DATA: {
            return state.setIn(["go-out", "dataRequested"], true);
        }
        case RECEIVE_GOOUT_DATA: {
            const apiData = action.payload;
            const { featuredData, groupedListData, picks, popular, tabList, text, dates, banners, digitalEventsCarousel, categories } = extractData(apiData);
            // if sortBy exists let it be, else do a default sort by date, asc
            return state.withMutations(m => m
                .setIn(["go-out", "dataReceived"], true)
                .setIn(["go-out", "dataRequested"], false)
                .setIn(["go-out", "featured"], featuredData)
                .mergeDeepIn(["go-out", "list"], groupedListData)
                .setIn(["go-out", "picks"], picks)
                .setIn(["go-out", "popular"], popular)
                .setIn(["go-out", "tabList"], tabList)
                .mergeDeepIn(["go-out", "text"], text)
                .setIn(["go-out", "dates"], dates)
                .setIn(["go-out", "banners"], banners)
                .setIn(["go-out", "digitalEventsCarousel"], digitalEventsCarousel)
                .setIn(["go-out", "categories"], categories));
        }
        case RECEIVE_POPULAR_DATA: {
            const popular = getPopular(action.payload);
            return state.setIn(["go-out", "popular"], popular);
        }
        case ERROR_GOOUT_DATA: {
            return state.withMutations(m => m.setIn(["go-out", "dataReceived"], false).set(["go-out", "dataRequested"], false));
        }
        case CLEAR_GOOUT_DATA: {
            return clearData(state, "go-out");
        }
        case CLEAR_STAYIN_DATA: {
            return clearData(state, "stay-in");
        }
        case SITE_DISPLAY_MODE_CHANGED: {
            const section = action.payload;
            if (section === "go-out" || state.has(section)) {
                return state;
            }
            return addSection(state, section);
        }
        case RECEIVE_EVENT_FAV: {
            const apiData = action.payload;
            const currentSlug = action.meta;
            if (!apiData && !apiData.has("data") && !slug) {
                return state;
            }
            if (state.hasIn(["go-out", "list", "masterList", currentSlug])) {
                return state.setIn(["go-out", "list", "masterList", currentSlug, "favStats"], apiData.get("data"));
            }
            else {
                const dataToInsert = new Map({
                    masterList: new Map({
                        [currentSlug]: new Map({
                            favStats: apiData.get("data")
                        })
                    })
                });
                return state.mergeDeepIn(["go-out", "list"], dataToInsert);
            }
        }
        default:
            return state;
    }
};
export default homepageData;

import { Map, List } from "immutable";
import { RECEIVE_CITIES_DATA, USER_SELECTED_CITY, SET_SELECTED_CITY_FROM_CACHE, SET_IS_SELECTED_CITY_INFERRED } from "../actions/index";
const initialState = new Map({
    cities: new List([]),
    selectedCity: "online",
    isSelectedCityInferred: false
});
const PINNED_CITIES = new List([
    "homescreen-delhi",
    "homescreen-mumbai",
    "homescreen-bengaluru",
    "homescreen-pune",
    "homescreen-kolkata",
    "homescreen-hyderabad",
    "homescreen-online",
    "homescreen-goa",
    "homescreen-chennai"
]);
const citiesData = (state = initialState, action) => {
    switch (action.type) {
        case RECEIVE_CITIES_DATA: {
            const apiData = action.payload;
            const cities = apiData.getIn(["data", "cities"]);
            const orderedCities = cities.map(c => {
                const pinnedIndex = PINNED_CITIES.findIndex(pc => pc === c.get("slug"));
                if (~pinnedIndex) {
                    return c.set("pinned", true).set("pinnedPosition", pinnedIndex);
                }
                return c.set("pinned", false);
            });
            return state.set("cities", orderedCities);
        }
        case USER_SELECTED_CITY: {
            return state.set("selectedCity", action.payload.toLowerCase());
        }
        case SET_IS_SELECTED_CITY_INFERRED: {
            return state.set("isSelectedCityInferred", action.payload);
        }
        default:
            return state;
    }
};
export default citiesData;

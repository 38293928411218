import { connect } from "react-redux";
import { withRouter } from "react-router";
// components
import AppBar from "../components/header/AppBar";
// actions + selectors
import { fetchCities, setSearchTab, toggleCitySelector, toggleMobileMenu, clearNotifications, setLoginError, setNotifications, setUserLoginData, resetModalType } from "../actions/index";
import { getAllCities, getGoOutGroups, getMobileMenuShowing, getSearchBarInView, getSelectedCityName, getShouldShowCitySelector, shouldShowMobileMenu, getIsSelectedCityInferred, isUserLoggedIn, getLoginDataReceived, getLockBodyScroll, getEmptyCartData, isSamsungDevice } from "../selectors/index";
import { getDisplayMode } from "../selectors/homepage";
const mapStateToProps = (state, props) => ({
    displayMode: getDisplayMode(state),
    groups: getGoOutGroups(state),
    isMobileMenuShowing: getMobileMenuShowing(state),
    selectedCity: getSelectedCityName(state),
    showNavSearchIcon: getSearchBarInView(state),
    shouldShowCitySelector: getShouldShowCitySelector(state),
    isSelectedCityInferred: getIsSelectedCityInferred(state),
    shouldShowMobileMenu: shouldShowMobileMenu(state),
    isLoggedIn: isUserLoggedIn(state),
    loginDataReceived: getLoginDataReceived(state),
    lockBodyScroll: getLockBodyScroll(state),
    emptyCart: getEmptyCartData(state),
    isSamsungDevice: isSamsungDevice(state)
});
const mapDispatchToProps = dispatch => ({
    setSearchTab: () => dispatch(setSearchTab(0)),
    toggleCitySelector: shouldShowCitySelector => dispatch(toggleCitySelector(shouldShowCitySelector)),
    toggleMobileMenu: () => dispatch(toggleMobileMenu()),
    clearNotifications: () => dispatch(clearNotifications()),
    notify: notification => dispatch(setNotifications(notification)),
    setLoginError: msg => dispatch(setLoginError(msg)),
    setUserLoginData: userInfo => dispatch(setUserLoginData(userInfo)),
    resetModalType: () => dispatch(resetModalType())
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AppBar));

import { ELPSorters } from "../helpers/constants";
/**
 * Filters cards by time
 * @param {string} timeFilter
 * @param {Immutable.Map} card
 * @returns {boolean}
 */
export const filterCardsByTime = (timeFilter, card) => {
    if (!timeFilter)
        return true;
    const matchesTimeFilter = card.get("applicable_filters", "").indexOf(timeFilter);
    return matchesTimeFilter > -1;
};
export const filterCardsByPrice = (priceFilter, card) => {
    if (!priceFilter)
        return true;
    const priceDisplayString = card.get("price_display_string");
    if (priceFilter === "free") {
        return ["0", "free"].includes(priceDisplayString.toLowerCase());
    }
    return priceDisplayString === priceFilter;
};
export const filterCardsByType = (typeFilter, card) => {
    if (!typeFilter || typeFilter == "all")
        return true;
    return card.get("event_type") === typeFilter;
};
/**
 * NOTE - Genre = Category in our parlance
 * @param {Immutable.Map} filterBy
 * @param {Immutable.Map} card
 * @returns {boolean}
 */
export const filterCardsByGenre = (filterBy, card) => {
    if (filterBy && filterBy.size > 0) {
        let shouldFilter = false;
        filterBy.forEach(options => {
            shouldFilter =
                options.size === 0
                    ? true
                    : options.reduce((isFiltered, option) => {
                        return isFiltered || card.get("category_id").get("name") === option;
                    }, false);
        });
        return shouldFilter;
    }
    return true;
};
export const filterCardsByPriceRange = (maxPriceFilter, card) => {
    if (!maxPriceFilter)
        return true;
    const priceDisplayString = card.get("price_display_string");
    if (priceDisplayString === "free" || priceDisplayString === "0") {
        return false;
    }
    else {
        return (Number((priceDisplayString.includes("-")
            ? priceDisplayString.substr(0, priceDisplayString.indexOf("-"))
            : priceDisplayString).replace(/[^0-9\.]/g, "")) <= Number(maxPriceFilter));
    }
};
/**
 * Sort shit
 * @param {Immutable.Map} sortBy
 * @param {Immutable.Map} secondarySortBy
 * @param {Immutable.Map} a
 * @param {Immutable.Map} b
 * @returns {number}
 */
export const sortCardsByOption = (sortBy, secondarySortBy, a, b) => {
    // 0 means no sort needed
    if (!sortBy || !sortBy.size)
        return 0;
    const sortKey = sortBy.get("key");
    const sortType = sortBy.get("type");
    const aValue = a.get(sortKey, 0);
    const bValue = b.get(sortKey, 0);
    // In case of tie, sort by secondarySortBy param (ascending)
    const secondarySortKey = secondarySortBy.get("key");
    if (bValue === aValue && sortKey !== secondarySortKey) {
        return a.get(secondarySortKey, 0) - b.get(secondarySortKey, 0);
    }
    if (sortType === "desc") {
        return bValue - aValue;
    }
    return aValue - bValue;
};
const identity = x => x;
/**
 *
 * @param {Immutable.List} cards
 * @param {Immutable.Map} filterBy
 * @param {Immutable.Map} sortBy
 * @param {string} timeFilter
 * @returns {Immutable.List}
 */
export const filterAndSortCards = (cards, filterBy, sortBy, timeFilter, priceFilter, typeFilter, maxPrice, secondarySortBy = ELPSorters.get("2")) => {
    // if filterBy, filter by filterBy next
    // if sortBy, sort the results finally with sortBy
    if (!filterBy && !sortBy && !timeFilter)
        return cards;
    // if time, filter by time first
    const timeFilterFn = filterCardsByTime.bind(null, timeFilter);
    const genreFilterFn = filterCardsByGenre.bind(null, filterBy || identity);
    const sortFn = sortCardsByOption.bind(null, sortBy, secondarySortBy);
    const priceFilterFn = filterCardsByPrice.bind(null, priceFilter);
    const typeFilterFn = filterCardsByType.bind(null, typeFilter);
    const priceRangeFilterFn = filterCardsByPriceRange.bind(null, maxPrice);
    return cards
        .filter(timeFilterFn)
        .filter(priceFilterFn)
        .filter(typeFilterFn)
        .filter(genreFilterFn)
        .filter(priceRangeFilterFn)
        .sort(sortFn);
};

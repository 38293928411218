var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
export default function clientMiddleware() {
    return ({ dispatch, getState }) => next => action => {
        if (typeof action === "function") {
            return action(dispatch, getState);
        }
        const { promise, types } = action, rest = __rest(action, ["promise", "types"]); // eslint-disable-line no-redeclare
        if (!promise) {
            return next(action);
        }
        const [REQUEST, SUCCESS, FAILURE] = types;
        next(Object.assign(Object.assign({}, rest), { type: REQUEST }));
        // promise is already executed
        promise
            .then(result => next(Object.assign({ payload: result, type: SUCCESS }, rest)), error => next(Object.assign(Object.assign({}, rest), { error, type: FAILURE })))
            .catch(error => {
            console.error("MIDDLEWARE ERROR:", error);
            next(Object.assign(Object.assign({}, rest), { error, type: FAILURE }));
        });
        return promise;
    };
}

import { getUserData } from "./index";
import { getMasterList } from "./homepage";
import { createSelector } from "reselect";
import { List } from "immutable";
import { NUM_RECENTLY_TO_DISPLAY_ON_HOME_PAGE } from "../helpers/constants";
import { checkValidEvent } from "../helpers/misc/recentlyViewedHelpers.ts";
export const getAllRecentlyViewed = createSelector(getUserData, user => {
    return user.get("recentlyViewed");
});
/**
 * Use this selector to get back explicitly
 * only the resource keys from this
 * for eg, events, movies
 * not state flags like isRehydrated
 */
export const getAllRecentlyViewedWithResourcesOnly = createSelector(getAllRecentlyViewed, recentlyViewed => {
    // other state / status keys can also
    // be here and deleted
    return recentlyViewed.delete("isRehydrated");
});
export const getRecentlyViewedEvents = createSelector(getAllRecentlyViewed, recentlyViewed => {
    return recentlyViewed.get("events");
});
export const getRecentlyViewedMovies = createSelector(getAllRecentlyViewed, recentlyViewed => {
    return recentlyViewed.get("movies");
});
export const getRecentlyViewedMasterList = createSelector(getAllRecentlyViewed, recentlyViewed => {
    return recentlyViewed.get("masterList");
});
/**
 * get active recently viewed events
 */
export const getActiveRecentlyViewedEvents = createSelector(getRecentlyViewedEvents, getMasterList, getRecentlyViewedMasterList, (recentEventIds, masterList, localMasterList) => {
    if (!recentEventIds || recentEventIds.size === 0)
        return List();
    // search in masterlist for each event
    return recentEventIds
        .map((eventId) => {
        let eventData = masterList.find(v => {
            if (v)
                return v.get("_id") === eventId;
            return false;
        });
        if (!eventData && localMasterList) {
            const localEventData = localMasterList.get(eventId, false);
            if (localEventData) {
                const isValid = checkValidEvent(localEventData);
                if (isValid) {
                    eventData = localEventData;
                }
            }
        }
        return eventData;
    })
        .filter(v => (v ? true : false))
        .take(NUM_RECENTLY_TO_DISPLAY_ON_HOME_PAGE);
});
export const getRehydratedStatus = createSelector(getAllRecentlyViewed, recentlyViewed => recentlyViewed.get("isRehydrated"));

/** @jsx jsx */
import { jsx, css } from "@emotion/core";
import React from "react";
import InsiderLink from "../containers/InsiderLinkWithSubscription";
import { sendClevertapEvent } from "../helpers/misc";
import { CLEVERTAP_ACTIONS } from "../helpers/constants/common";
import { NavItem, NavItemsContainer } from "./header/AppBar/style";
const Navbar = ({ items, isSelectedCityInferred, toggleCitySelector, location, overideCss }) => {
    if (!items) {
        return null;
    }
    const changeCurrentItem = (item, onClick) => {
        if (onClick) {
            onClick();
        }
        else {
            sendClevertapEvent(CLEVERTAP_ACTIONS.HEADER_NAV_CLICK, {
                Link: item
            });
        }
    };
    const onItemClick = () => {
        if (!isSelectedCityInferred)
            toggleCitySelector(true);
    };
    return (<div css={css(NavItemsContainer)} id="discovery-tab-list">
			{items.map(item => {
            const { title, link, icon, target, onClick, activeColor } = item;
            const currentPath = location.pathname + location.search;
            return (<InsiderLink key={`group-${title}`} href={link} onClick={onItemClick} target={target}>
						<div key={`group-${title}`} css={css([NavItem(currentPath === link && activeColor ? activeColor : undefined), overideCss])} onClick={() => {
                    changeCurrentItem(link, onClick);
                }}>
							{icon}
							{title && <div style={{ width: "max-content" }}>{title}</div>}
						</div>
					</InsiderLink>);
        })}
		</div>);
};
export default React.memo(Navbar);

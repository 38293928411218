/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import { useContext } from "react";
import CitySelectorContext from "../../contexts/CitySelectorContext";
import { DATA_REFS } from "../../helpers/constants/dataRefs";
import cookies from "browser-cookies";
import { connect } from "react-redux";
import { setIsChangedCity } from "../../actions/index";
const CitySelectorListItem = (props) => {
    const { handleCitySelect, cityLinkStylesToApply, city, isAlternateCitySelector, markCityChange } = props;
    const { redirectPathFn, source_page } = useContext(CitySelectorContext);
    const dataRef = isAlternateCitySelector ? DATA_REFS.CITY_SELECTOR_OVERLAY_EACH_CITY : DATA_REFS.CITY_SELECTOR_EACH_CITY;
    return <li onClick={() => {
            handleCitySelect(city.get("name"), source_page);
            window.location.reload();
        }} key={`city-${city.get("name")}`} data-ref={dataRef} className="city-list-each">
		<a onClick={() => {
            markCityChange();
            cookies.set("cachedCity", city.get("name").toLowerCase(), { secure: true, expires: 7 });
            cookies.set("newCity", city.get("name").toLowerCase(), { secure: true, expires: 7 });
            cookies.set("skip_online_overlay", "true", { secure: true, expires: 7 });
            window.location.reload();
        }} css={css(cityLinkStylesToApply)}>
			{" "}
			{city.get("name")}{" "}
		</a>
	</li>;
};
const mapDispatchToProps = dispatch => {
    return {
        markCityChange: () => dispatch(setIsChangedCity(true))
    };
};
export default connect(null, mapDispatchToProps)(CitySelectorListItem);

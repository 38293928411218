import { Map } from "immutable";
import { SET_PAYTM_WALLET_NUMBER, SET_PAYTM_WALLET_STATUS, SET_PAYTM_WALLET_MODAL_SCREEN, SET_PAYTM_WALLET_MODAL_ERROR, SET_PAYTM_WALLET_BALANCE, SET_PAYTM_WALLET_DATA } from "../actions/payments";
const initialState = new Map({
    status: "linked",
    number: "",
    modalScreen: "phoneNo",
    sourceScreen: "",
    error: "",
    balance: ""
});
const paytmWallet = (state = initialState, action) => {
    switch (action.type) {
        case SET_PAYTM_WALLET_MODAL_ERROR: {
            return state.set("error", action.payload);
        }
        case SET_PAYTM_WALLET_STATUS: {
            return state.withMutations(s => s.set("status", action.payload).set("error", ""));
        }
        case SET_PAYTM_WALLET_NUMBER: {
            return state.withMutations(s => s.set("number", action.payload).set("error", ""));
        }
        case SET_PAYTM_WALLET_MODAL_SCREEN: {
            let sourceScreen = state.get("modalScreen");
            return state.withMutations(s => s
                .set("modalScreen", action.payload)
                .set("sourceScreen", sourceScreen)
                .set("error", ""));
        }
        case SET_PAYTM_WALLET_BALANCE: {
            return state.withMutations(s => s.set("balance", action.payload).set("error", ""));
        }
        case SET_PAYTM_WALLET_DATA: {
            return state.withMutations(s => s
                .set("number", action.payload.number)
                .set("balance", action.payload.balance)
                .set("error", ""));
        }
        default:
            return state;
    }
};
export default paytmWallet;

import { Map, OrderedSet, OrderedMap } from "immutable";
import { RECEIVE_GOOUT_DATA, RECEIVE_STAYIN_DATA, APPLY_GOOUT_SORT_FILTERS } from "../actions/index";
const initialState = new OrderedMap({
    "go-out": new OrderedSet([]),
    "stay-in": new OrderedSet([]),
    text: new OrderedMap(),
    filterBy: new OrderedMap({}),
    sortBy: new OrderedMap({
        Category: new OrderedMap({ key: "min_show_start_time", type: "asc" })
    }),
    filters: new OrderedMap({
        Category: new OrderedMap({
            show: new OrderedSet([
                new OrderedMap({ display: "Today", key: "today" }),
                new OrderedMap({ display: "Tomorrow", key: "tomorrow" }),
                new OrderedMap({ display: "This Weekend", key: "weekend" })
            ])
        })
    }),
    sorters: new OrderedMap(new OrderedMap({
        Category: new OrderedSet([
            new OrderedMap({ display: "Date: Ascending", key: "min_show_start_time", type: "asc" }),
            new OrderedMap({ display: "Date: Descending", key: "min_show_start_time", type: "desc" }),
            new OrderedMap({ display: "Price: Ascending", key: "min_price", type: "asc" }),
            new OrderedMap({ display: "Price: Descending", key: "min_price", type: "desc" })
        ])
    }))
});
const getCategories = list => {
    return list.reduce((acc, groupItems, group) => {
        const categories = groupItems
            .map(v => v.getIn(["category_id", "name"]))
            .filter(v => v)
            .toOrderedSet();
        return acc.concat(categories);
    }, new OrderedSet([]));
};
const getCategorySEO = list => {
    return list.reduce((acc, groupItems, group) => {
        const categories = groupItems.reduce((a, item, idx) => {
            return a.set(item.getIn(["category_id", "name"]), new Map({
                seo_title: item.getIn(["category_id", "display_details", "seo_title"]),
                seo_description: item.getIn(["category_id", "display_details", "seo_description"])
            }));
        }, new Map({}));
        return acc.concat(categories);
    }, new OrderedMap());
};
const categories = (state = initialState, action) => {
    switch (action.type) {
        case RECEIVE_GOOUT_DATA: {
            const categories = getCategories(action.payload.get("list"));
            const categorySEO = getCategorySEO(action.payload.get("list"));
            return state.withMutations(m => m.setIn(["go-out"], categories).setIn(["text"], categorySEO));
        }
        case RECEIVE_STAYIN_DATA: {
            const categories = getCategories(action.payload.get("list"));
            return state.set("stay-in", categories);
        }
        case APPLY_GOOUT_SORT_FILTERS: {
            if (action.componentType === "category") {
                return state.setIn(["sortBy", "Category"], action.sort).setIn(["filterBy", "Category"], action.filters);
            }
        }
        default:
            return state;
    }
};
export default categories;

import { connect } from "react-redux";
// components
import { InsiderLink } from "../components/InsiderLink";
// filter out all disliked events
const mapStateToProps = (state, ownProps) => ({
    activeClassName: ownProps.activeClassName,
    href: ownProps.href,
    isSPA: Boolean(state.getIn(["uiState", "isSPA"])),
    onClick: ownProps.onClick,
    target: ownProps.target,
    id: ownProps.id
});
const InsiderLinkWithSubscription = connect(mapStateToProps, {})(InsiderLink);
export default InsiderLinkWithSubscription;

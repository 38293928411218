import { connect } from "react-redux";
import { withRouter } from "react-router";
// components
// selectors + actions
import { getIsSelectedCityInferred } from "../selectors/index";
import { toggleCitySelector } from "../actions/index";
import Navbar from "../components/Navbar";
const mapStateToProps = (state, ownProps) => {
    return {
        isSelectedCityInferred: getIsSelectedCityInferred(state),
        items: ownProps.items,
        overideCss: ownProps.overideCss
    };
};
const mapDispatchToProps = (dispatch) => ({
    // getHomepageData: (city: string) => dispatch(fetchGooutHomeResources(city)),
    toggleCitySelector: (shouldShowCitySelector) => dispatch(toggleCitySelector(shouldShowCitySelector))
});
const NavBarContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(Navbar));
export default NavBarContainer;

import { Map, OrderedSet } from "immutable";
import { RECEIVE_GOOUT_DATA, RECEIVE_STAYIN_DATA } from "../actions/index";
const initialState = new Map({
    "go-out": new OrderedSet([]),
    "stay-in": new OrderedSet([])
});
const getGroups = groups => {
    return groups.filter(v => v).toOrderedSet();
};
const groups = (state = initialState, action) => {
    switch (action.type) {
        case RECEIVE_GOOUT_DATA: {
            const groupNames = getGroups(action.payload.get("groups"));
            return state.updateIn(["go-out"], v => {
                return v.toOrderedSet().union(groupNames);
            });
        }
        case RECEIVE_STAYIN_DATA: {
            const groupNames = getGroups(action.payload.get("groups"));
            return state.updateIn(["stay-in"], v => {
                return v.toOrderedSet().union(groupNames);
            });
        }
        default:
            return state;
    }
};
export default groups;

/** @jsx jsx */
import InsiderLink from "../../../containers/InsiderLinkWithSubscription";
import { jsx, css } from "@emotion/core";
import theme from "../../../styles/common";
import { DATA_REFS } from "../../../helpers/constants/dataRefs";
const BorderBoxStyle = `
	box-sizing: border-box;
`;
const HomeLogoContainerStyles = `
	margin-left: 15px;

	@media screen and (max-width: 525px) {
		margin-left: 8px;
	}
	
	${theme.mediaQueries["for-pc"]} {
		display: inline-flex;
		position: static;
		tranform: none;
		float: left;
	}
`;
const HomeLogoStyles = `
	${BorderBoxStyle};
	display: block;
	text-decoration: none;
	background: transparent url('/assets/images/logo/insider-logo-light-color.svg') center 45% / contain no-repeat;
	width: 124px;
	// width: 95px;
	height: 19px;
	user-select: none;
	label: home-logo;

	${theme.mediaQueries["for-pc"]} {
		width: 158px;
		// width: 110px;
		height: 24px;
	}
`;
const HomeLogo = ({ isUserSelectingCity, href, toggleCitySelector, isSelectedCityInferred, shouldShowCitySelector }) => {
    return (<div css={css(HomeLogoContainerStyles)}>
			{isUserSelectingCity ? (<div css={css(HomeLogoStyles)}/>) : (<InsiderLink href={href} activeClassName={"active"} css={css(HomeLogoStyles)} onClick={() => {
                // if we are forcing the city selection here
                // this is not dismissable
                if (shouldShowCitySelector && !isSelectedCityInferred) {
                    toggleCitySelector(true);
                }
            }} dataRef={DATA_REFS.HOME_LOGO}/>)}
		</div>);
};
export default HomeLogo;

import semverCompare from "semver-compare";
import { MD5 } from "crypto-js";
export const createMD5hash = (ssoToken) => MD5(ssoToken).toString();
export var ALLOWED_PERMISSIONS;
(function (ALLOWED_PERMISSIONS) {
    ALLOWED_PERMISSIONS["CAMERA"] = "camera";
    ALLOWED_PERMISSIONS["MICROPHONE"] = "microphone";
    ALLOWED_PERMISSIONS["RECORD_AUDIO"] = "record_audio";
    ALLOWED_PERMISSIONS["READ_CALENDAR"] = "read_calendar";
    ALLOWED_PERMISSIONS["WRITE_CALENDAT"] = "write_calendar";
    ALLOWED_PERMISSIONS["READ_CALL_LOG"] = "read_call_log";
    ALLOWED_PERMISSIONS["WRITE_CALL_LOG"] = "write_call_log";
    ALLOWED_PERMISSIONS["PROCESS_OUTGOING_CALLS"] = "process_outgoing_calls";
    ALLOWED_PERMISSIONS["READ_CONTACTS"] = "read_contacts";
    ALLOWED_PERMISSIONS["WRITE_CONTACTS"] = "write_contacts";
    ALLOWED_PERMISSIONS["GET_ACCOUNTS"] = "get_accounts";
    ALLOWED_PERMISSIONS["LOCATION"] = "location";
    ALLOWED_PERMISSIONS["READ_PHONE_STATE"] = "read_phone_state";
    ALLOWED_PERMISSIONS["READ_PHONE_NUMBERS"] = "read_phone_numbers";
    ALLOWED_PERMISSIONS["CALL_PHONE"] = "call_phone";
    ALLOWED_PERMISSIONS["ANSWER_PHONE_CALLS"] = "answer_phone_calls";
    ALLOWED_PERMISSIONS["ADD_VOICEMAIL"] = "add_voicemail";
    ALLOWED_PERMISSIONS["USE_SIP"] = "use_sip";
    ALLOWED_PERMISSIONS["BODY_SENSORS"] = "body_sensors";
    ALLOWED_PERMISSIONS["SEND_SMS"] = "send_sms";
    ALLOWED_PERMISSIONS["RECEIVE_SMS"] = "receive_sms";
    ALLOWED_PERMISSIONS["READ_SMS"] = "read_sms";
    ALLOWED_PERMISSIONS["RECEIVE_WAP_PUSH"] = "receive_wap_push";
    ALLOWED_PERMISSIONS["RECEIVE_MMS"] = "receive_mms";
    ALLOWED_PERMISSIONS["READ_EXTERNAL_STORAGE"] = "read_external_storage";
    ALLOWED_PERMISSIONS["WRITE_EXTERNAL_STORAGE"] = "write_external_storage";
    ALLOWED_PERMISSIONS["PHOTOS"] = "photos";
    ALLOWED_PERMISSIONS["STORAGE"] = "storage";
})(ALLOWED_PERMISSIONS || (ALLOWED_PERMISSIONS = {}));
const checkPhoenixViaUserAgent = () => {
    const userAgent = navigator.userAgent;
    return /PhoenixContainer/.test(userAgent);
};
// wait for H5 bridge to be ready
// we initialise this immediately so the event listener can be added as quickly as possible
export const isH5BridgeReady = () => {
    const isPhoenixContainer = checkPhoenixViaUserAgent();
    if (!window) {
        return Promise.reject(false);
    }
    if (isPhoenixContainer && window.JSBridge) {
        return Promise.resolve(true);
    }
    if (window.AlipayJSBridge) {
        return Promise.resolve(true);
    }
    return new Promise(resolve => {
        document.addEventListener(`${isPhoenixContainer ? "JSBridgeReady" : "AlipayJSBridgeReady"}`, () => resolve(true), false);
    });
};
export const getJSBridge = () => {
    const isPhoenixContainer = checkPhoenixViaUserAgent();
    return isPhoenixContainer ? window.JSBridge : window.AlipayJSBridge;
};
/**
 * Saves data persistently on the native side
 * Documentation - https://wiki.mypaytm.com/display/h5/Android+Bridges
 * @param data
 */
export const saveData = (data) => isH5BridgeReady().then(() => new Promise((resolve, reject) => {
    const JSBridge = getJSBridge();
    JSBridge.call("paytmSaveData", {
        data: data,
        fileName: "events"
    }, (result) => {
        if (result && result.data && result.data.success) {
            resolve(result.data.success);
        }
        else {
            console.warn("bridge: paytmSaveData could not save data", data, result);
            reject(new Error(result.error.message));
        }
    });
}));
/**
 * Gets data from the shared native cache
 * Documentation - https://wiki.mypaytm.com/display/h5/Android+Bridges
 * @param keys
 */
export const getData = keys => isH5BridgeReady().then(() => new Promise(resolve => {
    const JSBridge = getJSBridge();
    JSBridge.call("paytmGetData", {
        keys,
        fileName: "events"
    }, (result) => {
        if (result && result.data) {
            resolve(result.data);
        }
        else {
            console.warn("bridge: paytmGetData failed", keys, result);
            resolve();
        }
    });
}));
const permissionGrantedHelper = (result, permission) => {
    return result && result.data && result.data[permission] === 1;
};
// Hack to handle paytm bug
const permissionGranted = (result, permission) => {
    if (permission === "location") {
        return permissionGrantedHelper(result, "access_coarse_location") || permissionGrantedHelper(result, "location");
    }
    return permissionGrantedHelper(result, permission);
};
/**
 * Checks if given permission has been give to android code
 * Documentation - https://wiki.mypaytm.com/display/h5/Android+Bridges
 * @param permission
 */
export const checkPermission = (permission) => isH5BridgeReady().then(() => {
    return new Promise((resolve, reject) => {
        const JSBridge = getJSBridge();
        JSBridge.call("paytmCheckPermission", {
            permission
        }, (result) => {
            if (permissionGranted(result, permission)) {
                resolve(true);
            }
            else {
                console.error("bridge: paytmCheckPermission permission not granted", permission, result);
                resolve(false);
            }
        });
    });
});
/**
 * Checks if given permission has been give to android code
 * Documentation - https://wiki.mypaytm.com/pages/viewpage.action?pageId=171236030
 * @param permission
 * @param {boolean} rejectPromiseOnDeny - Reject the promise when permission is denied
 */
export const requestPermission = (permission, rejectPromiseOnDeny) => isH5BridgeReady()
    .then(() => checkPermission(permission))
    .then(existingPermission => {
    if (existingPermission) {
        return Promise.resolve(true);
    }
    // ask for the permission explicitly
    return new Promise((resolve, reject) => {
        const JSBridge = getJSBridge();
        JSBridge.call("paytmRequestPermission", {
            permission
        }, (result) => {
            if (permissionGranted(result, permission)) {
                resolve(true);
            }
            else {
                console.error("bridge: paytmRequestPermission permission not granted", permission, result);
                rejectPromiseOnDeny ? reject(false) : resolve(false);
            }
        });
    });
});
/**
 * Requests access for the given list of permissions has been give to android code
 * Documentation - https://wiki.mypaytm.com/pages/viewpage.action?pageId=171236030
 * ANDROID ONLY
 * @param {ALLOWED_PERMISSIONS[]} permissions
 */
export const requestPermissions = (permissions) => isH5BridgeReady().then(() => new Promise((resolve, _reject) => {
    const JSBridge = getJSBridge();
    const permissionsString = permissions
        .filter(permission => typeof permission === "string" && permission.length > 0)
        .map(permission => permission.trim())
        .join(",");
    JSBridge.call("paytmRequestPermission", {
        permission: permissionsString
    }, (result) => {
        resolve(permissions.map(permission => permissionGranted(result, permission)));
    });
}));
const checkAndRequestPermission = (permission) => checkPermission(permission).then(result => (result ? result : requestPermission(permission, true)));
export const getDeviceManufacturer = () => {
    return isH5BridgeReady().then(() => {
        return new Promise((resolve, reject) => {
            const JSBridge = getJSBridge();
            JSBridge.call("paytmDeviceManufacturer", {}, function (result) {
                if (result && result.data) {
                    resolve(result.data);
                }
                else {
                    console.error("bridge: paytmDeviceManufacturer could not get device manufacturer", result);
                    reject(null);
                }
            });
        });
    });
};
export const getOS = () => {
    return getDeviceManufacturer().then(manufacturer => (manufacturer === "Apple" ? "ios" : "android"));
};
export const getLocation = () => {
    return isH5BridgeReady().then(() => {
        return new Promise((resolve, reject) => {
            const JSBridge = getJSBridge();
            JSBridge.call("paytmGetLocation", {}, (result) => {
                if (result && result.data) {
                    resolve(result.data);
                }
                else {
                    console.error("bridge: paytmGetLocation could not get location", result);
                    reject(null);
                }
            });
        });
    });
};
/**
 * Gets the location of the user
 * Documentation - https://myjsapi.alipay.com/jsapi/native/get-current-location.html
 */
export const requestPermissionAndGetLocation = () => isH5BridgeReady()
    .then(() => {
    return checkAndRequestPermission(ALLOWED_PERMISSIONS.LOCATION);
})
    .then(() => {
    return getLocation();
});
export const navigateTo = (page, data) => {
    const payload = {
        navigateTo: page,
        data
    };
    return isH5BridgeReady().then(() => new Promise(resolve => {
        const JSBridge = getJSBridge();
        JSBridge.call("paytmNavigateTo", payload, (result) => {
            if (result && result.data) {
                resolve(result.data);
            }
            else {
                console.error("bridge: paytmNavigateTo could not navigate to event pdp", payload, result);
                resolve();
            }
        });
    }));
};
export const setTitle = (title) => {
    return isH5BridgeReady().then(() => new Promise(resolve => {
        const JSBridge = getJSBridge();
        JSBridge.call("setTitle", {
            title: title
        }, () => {
            resolve();
        });
    }));
};
export const share = ({ title, subject, text, imageBase64 }) => {
    return isH5BridgeReady().then(() => {
        return new Promise(resolve => {
            const JSBridge = getJSBridge();
            JSBridge.call("paytmShareTextAndImage", {
                title,
                subject,
                text,
                imageBase64
            }, function (result) {
                resolve(result);
            });
        });
    });
};
export const paytmGetUserInfo = (keys) => {
    return isH5BridgeReady().then(() => {
        return new Promise(resolve => {
            const JSBridge = getJSBridge();
            JSBridge.call("paytmGetUserInfo", {
                infoKeys: keys // keys array
            }, function (result) {
                if (result && result.data) {
                    resolve(result.data);
                }
                else {
                    console.error("bridge: paytmGetUserInfo could not get user data", keys, result);
                    resolve();
                }
            });
        });
    });
};
export const getUserData = () => {
    const userDataKeys = ["userId", "email", "mobile", "firstName", "lastName"];
    return Promise.all([paytmGetUserInfo(userDataKeys), getEncryptedSsoToken()]).then(([data, encryptedSsoToken]) => {
        if (data) {
            return {
                customerId: data.userId,
                emailId: data.email,
                phoneNumber: data.mobile,
                firstName: data.firstName,
                lastName: data.lastName,
                encryptedSsoToken
            };
        }
        else {
            console.warn("Could not get user data from bridge", data);
            new Error("Could not get user data from bridge");
        }
    });
};
export const getPaytmStoreUserData = async () => {
    const data = await getData([
        "email",
        "first_name",
        "last_name",
        "phone_no",
        "customerId",
        "_id",
        "apiKey",
        "secret",
        "encryptedSsoToken"
    ]);
    const hashedEncryptedSsoToken = createMD5hash(data.encryptedSsoToken);
    return Object.assign(Object.assign({}, data), { hashedEncryptedSsoToken });
};
export const openInBrowser = (url) => {
    return isH5BridgeReady().then(() => {
        return new Promise(resolve => {
            const JSBridge = getJSBridge();
            JSBridge.call("openInBrowser", {
                url
            }, function (result) {
                resolve(result);
            });
        });
    });
};
// For versions >= 9.4, new auth bridges can be used
// For lower versions, we need to use older bridges
export const appVersionCheck = (appInfo, compareAgainst = "9.4") => {
    try {
        const version = appInfo.versionName || appInfo.appVersion || appInfo.appVersionName;
        // For sanity in case int is received or if undefined fall through catch
        const versionString = version.toString();
        return semverCompare(versionString, compareAgainst) !== -1;
    }
    catch (e) {
        // Assume apps will be >= 9.4
        console.warn("Could not detect app version");
        return true;
    }
};
export const login = () => {
    return isH5BridgeReady().then(async () => {
        const appInfo = await getAppInfo();
        const loginBridgeName = appVersionCheck(appInfo) ? "paytmAuthHandler" : "paytmLogin";
        return new Promise((resolve, reject) => {
            const JSBridge = getJSBridge();
            JSBridge.call(loginBridgeName, Object.assign({}, (loginBridgeName === "paytmAuthHandler" && { showLoading: false })), (result) => {
                if (result && result.data) {
                    resolve(result.data.success);
                }
                else {
                    console.warn("Could not login to paytm", result);
                    reject(new Error("Could not login to paytm"));
                }
            });
        });
    });
};
export const logout = () => {
    return isH5BridgeReady().then(() => {
        return new Promise((resolve, reject) => {
            const JSBridge = getJSBridge();
            JSBridge.call("paytmLogout", {}, (result) => {
                if (result && result.data) {
                    resolve(result.data.success);
                }
                else {
                    console.warn("Could not logout from paytm");
                    reject(new Error("Could not logout from paytm"));
                }
            });
        });
    });
};
export const sessionExpired = () => {
    return isH5BridgeReady().then(() => {
        return new Promise((resolve, reject) => {
            const JSBridge = getJSBridge();
            JSBridge.call("paytmAuthHandler", { showLoading: false }, (result) => {
                if (result && result.data && "success" in result.data) {
                    resolve(result.data.success);
                }
                else {
                    console.warn("Could not check session expired from paytm", result);
                    reject(new Error("Could not check session expired from paytm"));
                }
            });
        });
    });
};
export const confirmDialog = ({ title, message, okButton, cancelButton }) => {
    return isH5BridgeReady().then(() => {
        return new Promise(resolve => {
            const JSBridge = getJSBridge();
            JSBridge.call("confirm", {
                title,
                message,
                okButton,
                cancelButton
            }, (ok) => {
                resolve(ok);
            });
        });
    });
};
export const getEncryptedSsoToken = async () => {
    try {
        // fetch unencrypted sso token
        const sso_token = await fetchPaytmAuthToken();
        // encrpt the token via encryption API
        return await encryptSSOToken(sso_token);
    }
    catch (e) {
        console.warn("Could not get encryptedSsoToken", e);
        new Error("Could not get encryptedSsoToken");
    }
};
export const getVersion = () => {
    return isH5BridgeReady().then(() => {
        return new Promise(resolve => {
            const JSBridge = getJSBridge();
            JSBridge.call("getStartupParams", {
                key: ["fixVersion"]
            }, (data) => {
                try {
                    resolve(data.fixVersion.replace("v", ""));
                }
                catch (e) {
                    console.warn("bridge: could not get fixVersion");
                    resolve("0");
                }
            });
        });
    });
};
export const getAppInfo = () => {
    return isH5BridgeReady().then(() => {
        return new Promise(resolve => {
            const JSBridge = getJSBridge();
            JSBridge.call("paytmGetAppInfo", {}, (result) => {
                try {
                    resolve(result.data);
                }
                catch (e) {
                    console.warn("bridge: could not get app info");
                    resolve({});
                }
            });
        });
    });
};
// closes the h5 container
export const exitSession = async () => {
    await isH5BridgeReady();
    const JSBridge = getJSBridge();
    JSBridge.call("exitSession");
    return true;
};
export const openDeepLink = async (deeplink) => {
    await isH5BridgeReady();
    return new Promise(resolve => {
        const JSBridge = getJSBridge();
        JSBridge.call("paytmOpenDeeplink", {
            deeplink
        }, (result) => {
            resolve(result);
        });
    });
};
export const isPaytmTokenValid = () => {
    return isH5BridgeReady().then(async () => {
        const appInfo = await getAppInfo();
        if (appVersionCheck(appInfo, '10.27')) {
            return new Promise(resolve => {
                const JSBridge = getJSBridge();
                JSBridge.call("paytmFetchAuthToken", {}, (result) => {
                    try {
                        resolve(result.success);
                    }
                    catch (e) {
                        console.warn("bridge: could not get app info");
                        resolve(false);
                    }
                });
            });
        }
        return true;
    });
};
export const fetchPaytmAuthToken = () => {
    return isH5BridgeReady().then(async () => {
        const appInfo = await getAppInfo();
        // paytmFetchAuthToken is only available for version 10.27 for Events ios, returns junk data in prev versions
        const authBridgeName = appVersionCheck(appInfo, "10.27") ? "paytmFetchAuthToken" : "paytmAuthHandler";
        return new Promise((resolve, reject) => {
            const JSBridge = getJSBridge();
            JSBridge.call(authBridgeName, Object.assign({}, (authBridgeName === "paytmAuthHandler" && { showLoading: false })), (result) => {
                try {
                    if (authBridgeName === "paytmAuthHandler") {
                        resolve(result.data.authToken);
                    }
                    else {
                        resolve(result.data);
                    }
                }
                catch (e) {
                    reject("Could not fetch auth token");
                }
            });
        });
    });
};
export const getCustomerId = () => {
    return isH5BridgeReady().then(() => {
        return new Promise((resolve, reject) => {
            const JSBridge = getJSBridge();
            JSBridge.call("paytmFetchCustomerId", {}, (result) => {
                try {
                    resolve(result);
                }
                catch (e) {
                    reject("Could not fetch customer id");
                }
            });
        });
    });
};
export const pushWindow = (url) => {
    isH5BridgeReady().then(() => {
        const JSBridge = getJSBridge();
        JSBridge.call("pushWindow", {
            url,
            param: {}
        });
    });
};
export const encryptSSOToken = (sso_token) => {
    return isH5BridgeReady().then(() => {
        return new Promise((resolve, reject) => {
            const JSBridge = getJSBridge();
            JSBridge.call("httpRequest", {
                url: "https://paytm.com/v1/user/token/enc/generate",
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    sso_token
                }
            }, (res) => {
                try {
                    resolve(JSON.parse(res.data).sso_token_enc);
                }
                catch (e) {
                    reject("Could not enc sso token");
                }
            });
        });
    });
};
export const paytmDismissLoader = () => {
    console.log('Dismissing the native loader now');
    isH5BridgeReady().then(() => {
        const JSBridge = getJSBridge();
        JSBridge.call('paytmDismissLoader', {}, (result) => {
            console.log(JSON.stringify(result));
        });
    });
};
const subscribeBridge = ({ bridgeName, payload = {}, callBack = () => { } }) => {
    // logging bridge info, runs inside h5 container only
    console.info('Bridge subscribed', bridgeName, payload);
    try {
        if (window.JSBridge) {
            window.JSBridge.subscribe(bridgeName, payload, callBack);
        }
        else {
            document.addEventListener('JSBridgeReady', () => {
                window.JSBridge.subscribe(bridgeName, payload, callBack);
            }, false);
        }
    }
    catch (e) {
        callBack(e);
        console.warn('Error Calling the bridge', bridgeName, payload);
    }
};
const unSubscribeBridge = ({ bridgeName, payload = {}, callBack = () => { } }) => {
    // logging bridge info, runs inside h5 container only
    console.info('Bridge subscribed', bridgeName, payload);
    try {
        if (window.JSBridge) {
            window.JSBridge.unsubscribe(bridgeName, payload, callBack);
        }
        else {
            document.addEventListener('JSBridgeReady', () => {
                window.JSBridge.unsubscribe(bridgeName, payload, callBack);
            }, false);
        }
    }
    catch (e) {
        callBack(e);
        console.warn('Error Calling the bridge', bridgeName, payload);
    }
};
export const subscribeDeferredCheckoutFlow = (payload, callback) => {
    subscribeBridge({
        bridgeName: 'paytmDeferredCheckout',
        payload,
        callBack: (response) => {
            console.log(response);
            return callback(response);
        },
    });
};
export const callDeferredCheckoutFlow = async (payload) => {
    isH5BridgeReady().then(() => {
        const JSBridge = getJSBridge();
        JSBridge.call('paytmDeferredCheckout', payload);
    });
};
export const unSubscribeDeferredCheckoutFlow = async () => {
    unSubscribeBridge({
        bridgeName: 'paytmDeferredCheckout',
    });
};
export const resetDeferredCheckoutFlow = async () => {
    isH5BridgeReady().then(() => {
        const JSBridge = getJSBridge();
        JSBridge.call('paytmDeferredCheckout', {
            step: 'reset',
        });
    });
};
export const subscribeShowPaymentModes = (payload, callback) => {
    subscribeBridge({
        bridgeName: 'showPaymentModes',
        payload,
        callBack: (response) => {
            return callback(response);
        },
    });
};
export const unSubscribeShowPaymentModes = async () => {
    unSubscribeBridge({
        bridgeName: 'showPaymentModes',
    });
};
export const callShowPaymentModes = async (payload) => {
    isH5BridgeReady().then(() => {
        const JSBridge = getJSBridge();
        JSBridge.call('showPaymentModes', payload);
    });
};
export const resetShowPaymentModes = async () => {
    isH5BridgeReady().then(() => {
        const JSBridge = getJSBridge();
        JSBridge.call('showPaymentModes', {
            step: 'reset',
        });
    });
};
export const getDeviceName = () => {
    return isH5BridgeReady().then(() => {
        return new Promise(resolve => {
            const JSBridge = getJSBridge();
            JSBridge.call("paytmDeviceName", {}, (result) => {
                try {
                    resolve(result.data);
                }
                catch (e) {
                    console.warn("bridge: could not get device name");
                    resolve('');
                }
            });
        });
    });
};
export const callPaytmFetchprofiledata = () => {
    return isH5BridgeReady().then(async () => {
        const appInfo = await getAppInfo();
        // only available for app version > 10.41 and its a call and forget method
        if (appVersionCheck(appInfo, '10.41')) {
            const JSBridge = getJSBridge();
            JSBridge.call("paytmFetchprofiledata", {
                refreshRequired: true
            }, (result) => {
                try {
                    console.log("data", result);
                    return result;
                }
                catch (e) {
                    console.warn("bridge: could not callPaytmFetchprofiledata");
                }
            });
        }
        return true;
    });
};

/** @jsx jsx */
import { jsx, css } from "@emotion/core";
import React, { Fragment, useEffect, useState } from "react";
import { NavbarContainer, LeftContainer, HideLeftNav, RightContainer, ManageHeight } from "./style";
import { goToMobileCitySelector } from "./common";
import { CLEVERTAP_ACTIONS, GENERAL_ACTIONS, EVENT_CONTEXTS, GoogleAuthClientId } from "../../../helpers/constants";
import { generateCitySelectorLink, generateGooutLink, generateSEOGroupLink, generateLinkForAllEvents } from "../../../helpers/linkGenerators";
import Media from "react-media";
import AsyncMobileMenu from "../../AsyncMobileMenu";
import { addMediaType, colors, mediaFeatures } from "../../../styles/common";
import HomeLogo from "../HomeLogo";
import Navbar from "../../../containers/NavBarWithSubscription";
import { LocationIcon } from "../../ui/icons/LocationIcon";
import { capitalizeFirstLetter } from "../../../helpers/constants";
import BannerNav from "../../../containers/BannerNavWithSubscription";
import { AppMenu } from "./AppMenu";
import { fetchTokenInfo } from "../../../helpers/apiClient";
import { googleLoginSuccessCallback, sendClevertapEvent } from "../../../helpers/misc";
import { loginCallback } from "../../../helpers/login";
const AppBar = props => {
    const { isSelectedCityInferred, isSPA, location, isLoggedIn, setModalType, clearNotifications, notify, redirectUrl, resetModalType, setLoginError, setUserLoginData, toggleCitySelector, shouldShowCitySelector, selectedCity, emptyCart, loginDataReceived, modalType, lockBodyScroll, isSamsungDevice } = props;
    const [showMobileMenu, toggleMobileMenu] = useState(false);
    const isUserSelectingCity = !isSelectedCityInferred && isSPA && location.pathname.includes(generateCitySelectorLink());
    const { LOGIN } = GENERAL_ACTIONS;
    useEffect(() => {
        goToMobileCitySelector(props);
    }, [props]);
    useEffect(() => {
        if (loginDataReceived && !isLoggedIn) {
            loadOneTapLogin();
        }
    }, [loginDataReceived, isLoggedIn]);
    useEffect(() => {
        if (modalType || showMobileMenu || lockBodyScroll) {
            document.documentElement.classList.add("no-scroll");
        }
        else {
            document.documentElement.classList.remove("no-scroll");
        }
    }, [modalType, showMobileMenu, lockBodyScroll]);
    const oneTapInit = () => {
        if (!google) {
            console.log("Some error occured while loading the Google Script.");
        }
        google.accounts.id.initialize({ client_id: GoogleAuthClientId, callback: googleLoginSuccess });
        google.accounts.id.prompt();
    };
    const loadOneTapLogin = () => {
        const script = document.createElement("script");
        script.src = "https://accounts.google.com/gsi/client";
        script.async = true;
        script.defer = true;
        document.querySelector("body").appendChild(script);
        window.onGoogleLibraryLoad = () => {
            oneTapInit();
        };
    };
    const googleLoginSuccess = async (response) => {
        const tokenInfo = await fetchTokenInfo(response.credential);
        let userObj = {
            googleId: tokenInfo.sub,
            email: tokenInfo.email,
            accessToken: response.credential
        };
        sendClevertapEvent(CLEVERTAP_ACTIONS.ONE_TAP_LOGIN_SUCCESS, tokenInfo);
        await googleLoginSuccessCallback(userObj, setLoginError, (userData) => loginCallback(userData, setUserLoginData, setLoginError, resetModalType, notify, clearNotifications, redirectUrl), "google-one-tap");
    };
    const login = (e) => {
        if (isLoggedIn) {
            return false;
        }
        else {
            e.preventDefault();
            document.documentElement.classList.add("no-scroll");
            return setModalType({
                modalType: LOGIN,
                context: EVENT_CONTEXTS.HEADER,
                loginCallback: (userData) => loginCallback(userData, setUserLoginData, setLoginError, resetModalType, notify, clearNotifications, redirectUrl)
            });
        }
    };
    const getNavItems = (selectedCity) => {
        switch (selectedCity.toLowerCase()) {
            case "online":
                return [
                    {
                        title: "Popular Events",
                        link: generateSEOGroupLink(selectedCity, "Digital Events")
                    },
                    {
                        title: "Free Events",
                        link: `${generateSEOGroupLink(selectedCity, "Digital Events")}?priceFilter=free`
                    },
                    {
                        title: "Today's Events",
                        link: generateSEOGroupLink(selectedCity, "Digital Events", "today")
                    }
                ];
            default:
                return [
                    {
                        title: "Events in " + capitalizeFirstLetter(selectedCity),
                        link: generateLinkForAllEvents(selectedCity),
                        icon: <LocationIcon size="24" stroke="white" cssStyle={`margin-right: 6px;`}/>,
                        activeColor: "blue"
                    }
                ];
        }
    };
    return (<Fragment>
			<div id="navbar" css={css(NavbarContainer)}>
				<div css={css(LeftContainer)}>
					<AppMenu isUserSelectingCity={isUserSelectingCity} toggleMobileMenu={toggleMobileMenu} showMobileMenu={showMobileMenu}/>
					<HomeLogo isUserSelectingCity={isUserSelectingCity} isSelectedCityInferred={isSelectedCityInferred} toggleCitySelector={toggleCitySelector} shouldShowCitySelector={shouldShowCitySelector} href={generateGooutLink(selectedCity)}/>
					<Navbar items={getNavItems(selectedCity)} overideCss={HideLeftNav(location.pathname.endsWith("/event"), emptyCart)}/>
				</div>
				<div css={css(RightContainer)}>
					<BannerNav isUserSelectingCity={isUserSelectingCity}/>
				</div>

				<Media query={`${addMediaType} ${mediaFeatures["for-mobile-and-tablet"]}`} render={() => (<AsyncMobileMenu login={login} toggleMobileMenu={toggleMobileMenu} showMobileMenu={showMobileMenu}/>)}/>
			</div>
			<div css={css([ManageHeight, `background: ${colors.primaryDark};`])}/>
		</Fragment>);
};
export default React.memo(AppBar);

import theme from "../styles";
/**
 * @deprecated Use the `classnames` package instead
 * @param {boolean} predicate
 * @param {string} basename
 * @param {string} extension
 * @return {string}
 */
export const generateClassName = (predicate, basename, extension) => {
    if (predicate) {
        return basename + " " + extension;
    }
    return basename;
};
export const validateMediaQuery = mediaQuery => window && window.matchMedia(mediaQuery).matches;

import { connect } from "react-redux";
// components
import Cart from "../components/header/Cart";
// actions + selectors
import { clearCart, fetchCart } from "../actions/index";
import { getCartItemCount, getClockSkewForCart, getIsCartEmpty, getCartExpiry, getCartItemsData, } from "../selectors/cart";
import { getPayableCost } from "../selectors";
import { withRouter } from "react-router";
const mapStateToProps = (state) => ({
    expires: getCartExpiry(state),
    isCartEmpty: getIsCartEmpty(state),
    numItems: getCartItemCount(state),
    clockSkew: getClockSkewForCart(state),
    cartItemsData: getCartItemsData(state),
    payableCost: getPayableCost(state),
});
const mapDispatchToProps = (dispatch) => ({
    clearCart: () => dispatch(clearCart()),
    fetchCart: () => dispatch(fetchCart())
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Cart));

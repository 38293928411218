import { Map, List } from "immutable";
import { REQUEST_DISCOUNT_OFFERS, RECEIVE_DISCOUNT_OFFERS, ERROR_DISCOUNT_OFFERS } from "../actions/index";
import { NewIcon } from "../components/ui/icons/NewIcon";
const initialState = new Map({
    eventDiscountOffer: new Map({}),
    isDiscountOfferLoading: false,
    discountOfferError: ""
});
const discountOffers = (state = initialState, action) => {
    switch (action.type) {
        case REQUEST_DISCOUNT_OFFERS: {
            return state.withMutations(s => {
                s.set("isDiscountOfferLoading", true);
                s.set("discountOfferError", "");
            });
        }
        case RECEIVE_DISCOUNT_OFFERS: {
            const payload = action.payload ? action.payload.get("data") : undefined;
            let { eventId, eventSlug } = action.meta;
            if (payload) {
                let allOffers = new List(payload.map(data => {
                    return new Map({
                        couponCode: data.get("couponCode"),
                        discountTitle: data.get("discountTitle"),
                        discountBody: data.get("discountBody"),
                        linkCTA: data.get("linkCTAText"),
                        mappingLink: data.get("mappingLink")
                    });
                }));
                return state.withMutations(s => {
                    s.set("isDiscountOfferLoading", false);
                    s.set("discountOfferError", "");
                    s.updateIn(["eventDiscountOffer", eventSlug], value => allOffers);
                });
            }
            else {
                return state;
            }
        }
        case ERROR_DISCOUNT_OFFERS: {
            return state.withMutations(s => {
                s.set("isDiscountOfferLoading", false);
                s.set("discountOfferError", "Error fetching active offers for EDP");
            });
        }
        default:
            return state;
    }
};
export default discountOffers;

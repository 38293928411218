/**
 * This lib is used for us to hydrate persistent data for out store
 * from the client storage. For eg, the list of recently viewed, some common
 * user info etc.
 *
 * How to use this -
 * 1. If you want to store state relating to the user that needs to be secure,
 *    and is not very volumnious, then store it in the server sesssion. eg - Cart
 * 2. If this is relatively volumnious, and you don't want to keep hitting the server for it
 *    then store it locally. The only tricky thing here is to wipe local store out when
 *    the user logs out
 */
import { get, set, clear } from "idb-keyval";
const safeImmutableObj = (value) => {
    if (value["toJS"])
        return value.toJS();
    return value;
};
export function setInCache(cacheKey, cacheValue) {
    return set(cacheKey, safeImmutableObj(cacheValue));
}
export function getFromCache(cacheKey) {
    return get(cacheKey);
}
export function clearCache() {
    return clear();
}

import { fromJS } from "immutable";
import { CLEAR_SITE_DISPLAY_MODE, NAVIGATED_TO_SUMMER_LANDING_PAGE, SET_PLATFORM, SET_SEARCH_BAR_IN_VIEW, SET_SEARCH_TAB, SET_SPA_MODE, SHOW_CITY_SELECTOR, SITE_DISPLAY_MODE_CHANGED, TAB_SELECTED, TOGGLE_BODY_SCROLL, TOGGLE_EXPLORE_MENU, TOGGLE_MOBILE_MENU, SET_SOCIAL_LOGIN_FLAG, IS_SAMSUNG_WALLET_SUPPORTED } from "../actions/index";
const initialState = fromJS({
    displayMode: "go-out",
    searchBarInView: true,
    isExploreMenuShowing: false,
    isMobileMenuShowing: false,
    isSPA: true,
    isSummerLandingPage: false,
    platform: "",
    searchTab: 0,
    selectedTabs: {
        "go-out": "",
        "stay-in": ""
    },
    shouldShowCitySelector: false,
    lockBodyScroll: false,
    disableSocialLogin: false
});
export default (state = initialState, action) => {
    switch (action.type) {
        case SHOW_CITY_SELECTOR:
            return state.set("shouldShowCitySelector", action.payload);
        case SITE_DISPLAY_MODE_CHANGED:
            return state.set("displayMode", action.payload);
        case CLEAR_SITE_DISPLAY_MODE:
            return state.set("displayMode", initialState.get("displayMode"));
        case SET_SEARCH_BAR_IN_VIEW:
            return state.set("searchBarInView", action.payload);
        case TAB_SELECTED:
            return state.withMutations(s => s
                .setIn(["selectedTabs", action.payload.displayMode], action.payload.selectedTabName)
                .setIn(["isExploreMenuShowing"], false));
        case SET_PLATFORM:
            return state.set("platform", action.payload);
        case SET_SPA_MODE:
            return state.set("isSPA", action.payload);
        case TOGGLE_EXPLORE_MENU:
            return state.set("isExploreMenuShowing", !state.get("isExploreMenuShowing"));
        case TOGGLE_MOBILE_MENU:
            return state.set("isMobileMenuShowing", !state.get("isMobileMenuShowing"));
        case NAVIGATED_TO_SUMMER_LANDING_PAGE:
            return state.set("isSummerLandingPage", action.payload);
        case SET_SEARCH_TAB:
            return state.set("searchTab", action.payload);
        case TOGGLE_BODY_SCROLL:
            return state.set("lockBodyScroll", action.payload);
        case SET_SOCIAL_LOGIN_FLAG:
            return state.set("disableSocialLogin", action.payload);
        case IS_SAMSUNG_WALLET_SUPPORTED:
            return state.set("isSamsungWalletDevice", action.payload);
        default:
            return state;
    }
};

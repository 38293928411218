import { Map } from "immutable";
import { IS_CITY_CHANGED } from "../actions/index";
const initialState = new Map({
    isCityChanged: false
});
const cityChanged = (state = initialState, action) => {
    switch (action.type) {
        case IS_CITY_CHANGED: {
            return state.set("isCityChanged", action.payload);
        }
        default:
            return state;
    }
};
export default cityChanged;

import { Map, List } from "immutable";
import { REQUEST_MATCH_SCHEDULE_EVENTS_DATA, RECEIVE_MATCH_SCHEDULE_EVENTS_DATA, ERROR_MATCH_SCHEDULE_EVENTS_DATA } from "../actions/index";
const initialState = new Map({
    data: List([]),
    dataReceived: false,
    dataErrored: false
});
export const islMatchScheduleEvents = (state = initialState, action) => {
    switch (action.type) {
        case RECEIVE_MATCH_SCHEDULE_EVENTS_DATA: {
            return state.withMutations(s => s.setIn(["data"], action.payload).setIn(["dataReceived"], true));
        }
        case ERROR_MATCH_SCHEDULE_EVENTS_DATA: {
            return state.withMutations(s => s.setIn(["data"], new List([])).setIn(["dataErrored"], true));
        }
        default:
            return state;
    }
};

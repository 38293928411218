import { fromJS } from "immutable";
const initialState = fromJS({
    "go-out": {
        likes: [],
        dislikes: []
    },
    "stay-in": {
        likes: [],
        dislikes: []
    }
});
/** @todo to be implemented **/
const recommended = (state = initialState, action) => {
    switch (action.type) {
        default:
            return state;
    }
};
export default recommended;

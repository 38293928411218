import { Map, List } from "immutable";
import { REQUEST_CART_DATA, RECEIVE_CART_DATA, ERROR_CART_DATA, APPLY_DISCOUNT_COUPON_SUCCESS, APPLY_DISCOUNT_COUPON_ERROR, CLEAR_CART_SUCCESS, RECEIVE_LOGIN_DATA, COMMIT_CART, ADD_ITEM_TO_CART_ERROR, CLEAR_ADD_ITEM_TO_CART_ERROR, REMOVE_CART_ITEM, REMOVE_CART_ITEM_ERROR } from "../actions/index";
import { devicePlatform } from "../helpers/constants";
import { ERROR_PAYTM_PROMO_CODE_DATA, RECEIVE_PAYTM_PROMO_CODE_DATA, REQUEST_PAYTM_PROMO_CODE_DATA } from "../actions/paytmPromoCodes";
const initialState = new Map({
    cart: new Map(),
    requestingCartData: false,
    emptyCart: true,
    discountCouponError: "",
    paytmPromoCodes: List([]),
    paytmPromoCodeDataReceived: false
});
const userData = (state = initialState, action) => {
    switch (action.type) {
        case REQUEST_CART_DATA:
        case REMOVE_CART_ITEM:
            return state.withMutations(s => s.set("requestingCartData", true));
        case RECEIVE_CART_DATA: {
            if (!action.payload || (action.payload.has("noCart") && action.payload.get("noCart"))) {
                // empty cart
                return state.withMutations(s => s.set("cart", new Map()).set("emptyCart", true).set("requestingCartData", false));
            }
            // Filter out zero count items.
            const cart = action.payload.withMutations(c => {
                return c.set("items", c.get("items").filter(item => item.get("count") !== 0));
            });
            const emptyCart = cart.get("items").size > 0 ? false : true;
            const now = Date.now() / 1000;
            return state.withMutations(s => s
                .set("requestingCartData", false)
                .set("emptyCart", emptyCart)
                .set("cart", cart)
                .setIn(["cart", "device_platform"], devicePlatform)
                .set("clockSkew", now - cart.get("serverTime", now)));
        }
        case RECEIVE_LOGIN_DATA: {
            return state;
        }
        case ERROR_CART_DATA: {
            return initialState.withMutations(s => s.set("requestingCartData", false));
        }
        case APPLY_DISCOUNT_COUPON_SUCCESS: {
            return state.withMutations(s => s.set("discountCouponError", ""));
        }
        case APPLY_DISCOUNT_COUPON_ERROR: {
            return state.withMutations(s => s.set("discountCouponError", action.error));
        }
        case CLEAR_CART_SUCCESS: {
            return state.withMutations(s => s.set("cart", new Map()).set("emptyCart", true));
        }
        case REQUEST_PAYTM_PROMO_CODE_DATA: {
            return state.withMutations(s => s.set("paytmPromoCodeDataReceived", true));
        }
        case RECEIVE_PAYTM_PROMO_CODE_DATA: {
            const codes = action.payload
                // Merge codes for different product ids
                .map(x => x.get("codes"))
                .reduce((x, acc) => acc.concat(x), List())
                // Remove duplicate codes
                .groupBy(x => x.get("code"))
                .map(x => x.first())
                .toList();
            return state.withMutations(s => s.set("paytmPromoCodes", codes));
        }
        case ERROR_PAYTM_PROMO_CODE_DATA: {
            return state;
        }
        case ADD_ITEM_TO_CART_ERROR: {
            return state
                .withMutations(s => s.set("addItemError", action.payload.error))
                .withMutations(s => s.set("addItemErrorStatusCode", action.payload.code))
                .withMutations(s => s.set("errorItem", action.payload.item));
        }
        case CLEAR_ADD_ITEM_TO_CART_ERROR: {
            return state
                .withMutations(s => s.set("addItemError", ""))
                .withMutations(s => s.set("addItemErrorStatusCode", ""))
                .withMutations(s => s.set("errorItem", undefined));
        }
        case REMOVE_CART_ITEM_ERROR:
            return state.withMutations(s => s.set("requestingCartData", false));
        default:
            return state;
    }
};
export default userData;

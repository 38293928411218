import { connect } from "react-redux";
import CitySelector from "../components/header/CitySelector";
import { getSelectedCityName, getCitiesGroupedByPinned, getShouldShowCitySelector, getEmptyCartData } from "../selectors/index";
import { withRouter } from "react-router";
const mapStateToProps = (state) => ({
    cities: getCitiesGroupedByPinned(state),
    selectedCity: getSelectedCityName(state),
    shouldShowCitySelector: getShouldShowCitySelector(state),
    emptyCart: getEmptyCartData(state),
});
export default withRouter(connect(mapStateToProps)(CitySelector));

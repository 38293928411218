// We need to insure these cannot be accessible by third party scripts running on the page
// We get these from the isLoggedIn call so we don't need to persist these across sessions
const store = {};
export const getApiKey = () => {
    return store["apiKey"];
};
export const setApiKey = (value) => {
    return (store["apiKey"] = value);
};
export const getApiSecret = () => {
    return store["secret"];
};
export const setApiSecret = (value) => {
    return (store["secret"] = value);
};
export const getEncryptedSsoToken = () => {
    return store["encryptedSsoToken"];
};
export const setEncryptedSsoToken = (value) => {
    return (store["encryptedSsoToken"] = value);
};

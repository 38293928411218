/** @jsx jsx */
import { jsx, css } from "@emotion/core";
import { HamburgerIcon, MobileMenuStyles } from "./style";
import classNames from "classnames";
import { DATA_REFS } from "../../../helpers/constants/dataRefs";
import { MenuIcon } from "../../ui/icons/MenuIcon";
import { sendClevertapEvent } from "../../../helpers/misc";
import { EVENT_CATEGORIES, GENERAL_ACTIONS, HIT_TYPES } from "../../../helpers/constants";
export const AppMenu = ({ isUserSelectingCity, toggleMobileMenu, showMobileMenu }) => {
    const { OPEN, SEND } = GENERAL_ACTIONS, { SIDEBAR } = EVENT_CATEGORIES, { EVENT } = HIT_TYPES;
    const enableMobileMenu = (e) => {
        const pathname = window.location.pathname;
        if (window.ga) {
            window.ga(SEND, {
                hitType: EVENT,
                eventCategory: SIDEBAR,
                eventAction: OPEN,
                eventLabel: pathname
            });
        }
        sendClevertapEvent(SIDEBAR, {
            action: OPEN,
            url: pathname
        });
        document.documentElement.classList.add("no-scroll");
        toggleMobileMenu(!showMobileMenu);
        e.preventDefault();
    };
    return (<div css={css(MobileMenuStyles)}>
			<button onClick={e => {
            !isUserSelectingCity && enableMobileMenu(e);
        }} className={classNames({ disabled: isUserSelectingCity })} css={css(HamburgerIcon)} data-ref={DATA_REFS.HAMBURGER_ICON}>
				<MenuIcon />
			</button>
		</div>);
};

import { Map } from "immutable";
import { REQUEST_EVENT_DATA, RECEIVE_EVENT_DATA, CLEAR_EVENT_DATA, RECEIVE_ACTIVITY_DATA, REQUEST_ACTIVITY_DATA, REQUEST_RELATED_EVENTS_DATA, RECEIVE_RELATED_EVENTS_DATA, REQUEST_EVENT_AD_BANNER_DATA, RECEIVE_EVENT_AD_BANNER_DATA } from "../actions";
import { isEmpty } from "lodash";
const initialState = new Map({});
const event = (state = initialState, action) => {
    let slug;
    switch (action.type) {
        case REQUEST_EVENT_DATA:
            slug = action.meta.slug;
            return state.setIn([slug, "dataRequested"], true);
        case RECEIVE_EVENT_DATA:
            slug = action.meta.slug;
            const apiData = action.payload.get("data").withMutations(event => {
                // convert price string to Free
                const eventWithFreePrice = event.get("price_display_string") === "0" ? event.set("price_display_string", "Free") : event;
                // if no venue, assume the event state is expired
                const eventWithNoVenueEventState = !eventWithFreePrice.has("venue")
                    ? eventWithFreePrice.set("event_state", "expired")
                    : eventWithFreePrice;
                event.set("start_utc_timestamp", event.getIn(["venue", "shows", 0, "start_utc_timestamp"]));
                event.set("end_utc_timestamp", event.getIn(["venue", "shows", 0, "end_utc_timestamp"]));
                // since this comes from the API, we can set partial to false here
                event.set("partial", false);
                return eventWithNoVenueEventState;
            });
            return state.withMutations(state => {
                state.setIn([slug, "dataRequested"], false);
                return state.setIn([slug, "insider"], apiData);
            });
        case CLEAR_EVENT_DATA:
            return initialState;
        case REQUEST_ACTIVITY_DATA:
            slug = action.meta.slug;
            return state.setIn([slug, "activityDataRequested"], true);
        case RECEIVE_ACTIVITY_DATA:
            slug = action.meta.slug;
            return state.setIn([slug, "activityDataReceived"], true);
        case REQUEST_RELATED_EVENTS_DATA:
            slug = action.meta.slug;
            return state.setIn([slug, "relatedEvents", "dataRequested"], true);
        case RECEIVE_RELATED_EVENTS_DATA:
            slug = action.meta.slug;
            return state.withMutations(state => {
                state.setIn([slug, "relatedEvents", "dataReceived"], true);
                return state.setIn([slug, "relatedEvents", "data"], action.payload.get("data"));
            });
        case REQUEST_EVENT_AD_BANNER_DATA:
            slug = action.meta.slug;
            return state.setIn([slug, "eventAdBanner", "dataRequested"], true);
        case RECEIVE_EVENT_AD_BANNER_DATA:
            slug = action.meta.slug;
            return state.withMutations(state => {
                state.setIn([slug, "eventAdBanner", "dataReceived"], true);
                return state.setIn([slug, "eventAdBanner", "data"], action.payload.get("data"));
            });
        default:
            return state;
    }
};
export default event;

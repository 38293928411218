export const DATA_REFS = {
    HAMBURGER_ICON: "hamburger_icon",
    MENU_CLOSE_ICON: "menu_close_icon",
    HOME_LOGO: "home_logo",
    HOME_LOGO_MOBILE_MENU: "home_logo_mobile_menu",
    SEARCH_ICON_HEADER: "search_icon_header",
    SEARCH_ICON_HOME: "search_icon_home",
    EDP_BUY_BUTTON: "edp_buy_button",
    EDP_PRICE_STRING: "edp_price_string",
    EDP_SHARE_BUTTON: "edp_share_button",
    EDP_SHARE_ICON: "edp_share_icon",
    EDP_VIDEO_EMBED: "edp_video_embed",
    IMAGE_LIST: "image_list",
    EDP_EVENT_TITLE: "edp_event_title",
    EDP_EVENT_CATEGORY: "edp_event_category",
    EDP_EVENT_DATESTRING: "edp_event_datestring",
    EDP_EVENT_VENUE: "edp_event_venue",
    EDP_EVENT_BANNER_IMAGE: "edp_event_banner_image",
    EDP_EVENT_AD_BANNER_IMAGE: "edp_event_ad_banner_image",
    EDP_RELATED_CAROUSEL: "edp_related_carousel",
    EDP_RELATED_CAROUSEL_CTA: "edp_related_carousel_cta",
    EVENT_CARD: "event_card",
    EVENT_CARD_IMAGE: "event_card_image",
    EVENT_CARD_TITLE: "event_card_title",
    EVENT_CARD_DATE_STRING: "event_card_date_string",
    EVENT_CARD_LOCATION: "event_card_location",
    EVENT_CARD_PRICE_STRING: "event_card_price_string",
    HOME_CITY_SELECTED_MOBILE: "home_city_selected_mobile",
    CITY_SELECTOR_OVERLAY_SKIP: "city_selector_overlay_skip",
    CITY_SELECTOR_OVERLAY_DROPDOWN: "city_selector_overlay_dropdown",
    CITY_SELECTOR_OVERLAY_EACH_CITY: "city_selector_overlay_each_city",
    CITY_SELECTOR_EACH_CITY: "city_selector_each_city",
    CITY_SELECTOR_MOBILE_EACH_CITY: "city_selector_mobile_each_city",
    CITY_SELECTOR_MOBILE_SEARCH_CITY: "city_selector_mobile_search_city",
    SEARCH_INPUT: "search_input",
    TRENDING_EVENTS_LABLE: "trending_events_label",
    CAROUSEL_TITLE: "carousel_title",
    PROMO_CODE_INPUT_H5: "promo_code_input_h5",
    PROMO_CODE_APPLY_H5: "promo_code_apply_h5",
    CHECKOUT_REMOVE_ITEM: "checkout_remove_item",
    CHECKOUT_CONV_FEE_LABEL: "checkout_conv_fee_label",
    CHECKOUT_CONV_FEE_VALUE: "checkout_conv_fee_value",
    CHECKOUT_GST_LABEL: "checkout_gst_label",
    CHECKOUT_GST_VALUE: "checkout_gst_value",
    CHECKOUT_TOTAL_LABEL: "checkout_total_label",
    CHECKOUT_TOTAL_VALUE: "checkout_total_value",
    CHECKOUT_PROCEED_TO_PAY: "checkout_proceed_to_pay",
    CHECKOUT_USER_TITLE: "checkout_user_title",
    CHECKOUT_USER_SIGN_OUT: "checkout_user_sign_out"
};

export const memoize = (func) => {
    const cache = new Map();
    return function (...args) {
        let key;
        if (args.length > 0) {
            key = args;
        }
        else {
            key = "default";
        }
        if (cache.has(key)) {
            return cache.get(key);
        }
        else {
            const result = func(...args);
            cache.set(key, result);
            return result;
        }
    };
};

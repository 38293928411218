// wait for H5 bridge to be ready
// we initialise this immediately so the event listener can be added as quickly as possible
export const isH5BridgeReady = () => {
    if (!window) {
        return Promise.reject(false);
    }
    const isPhoenixContainer = checkPhoenixViaUserAgent();
    if (isPhoenixContainer && window.JSBridge) {
        return Promise.resolve(true);
    }
    if (window.AlipayJSBridge) {
        return Promise.resolve(true);
    }
    return new Promise(resolve => {
        document.addEventListener(`${isPhoenixContainer ? "JSBridgeReady" : "AlipayJSBridgeReady"}`, () => resolve(true), false);
    });
};
export const getJSBridge = () => {
    const isPhoenixContainer = checkPhoenixViaUserAgent();
    return isPhoenixContainer ? window.JSBridge : window.AlipayJSBridge;
};
export const loginForApp = (params) => {
    let loginParams = params || {};
    return isH5BridgeReady().then(() => {
        return new Promise((resolve, reject) => {
            const JSBridge = getJSBridge();
            JSBridge.call("paytmLogin", loginParams, (result) => {
                if (result && result.data) {
                    resolve(result.data);
                }
                else {
                    console.warn("Could not login to paytm", result);
                    reject(new Error("Could not login to paytm"));
                }
            });
        });
    });
};
export const getUserDetailsForApp = () => {
    return isH5BridgeReady().then(() => {
        return new Promise((resolve, reject) => {
            const JSBridge = getJSBridge();
            JSBridge.call("getUserDetails", {}, (result) => {
                if (result && result.data && result.data.apiKey) {
                    resolve(result.data);
                }
                else {
                    console.warn("Could not fetch user details", result);
                    reject(new Error("Could not fetch user details"));
                }
            });
        });
    });
};
export const popWindowForApp = () => {
    return isH5BridgeReady().then(() => {
        const JSBridge = getJSBridge();
        JSBridge.call("popWindow");
    });
};
export const openInBrowserForApp = (url) => {
    return isH5BridgeReady().then(() => {
        const JSBridge = getJSBridge();
        JSBridge.call("openInBrowser", {
            url: url
        });
    });
};
export const hideTitleBarForApp = () => {
    return isH5BridgeReady().then(() => {
        const JSBridge = getJSBridge();
        JSBridge.call("paytmShowTitleBar", {
            value: false
        }, function (_result) {
            // do nothing
        });
    });
};
export const showTitleBarForApp = () => {
    return isH5BridgeReady().then(() => {
        const JSBridge = getJSBridge();
        JSBridge.call("paytmShowTitleBar", {
            value: true
        }, function (_result) {
            // do nothing
        });
    });
};
export const navigateToPageInApp = (pageName, data) => {
    return isH5BridgeReady().then(() => {
        const JSBridge = getJSBridge();
        JSBridge.call("paytmNavigateTo", {
            navigateTo: pageName,
            clearBackStack: false,
            finishThis: false,
            data
        });
    });
};
export const hideTitleBarCrossIcon = () => {
    return isH5BridgeReady().then(() => {
        const JSBridge = getJSBridge();
        JSBridge.call("setCloseButton", {
            action: "hide"
        });
    });
};
export const setAppTitle = (title) => {
    return isH5BridgeReady().then(() => {
        const JSBridge = getJSBridge();
        JSBridge.call("setTitle", {
            title: title
        });
    });
};
export const checkPhoenixViaUserAgent = () => {
    const userAgent = navigator.userAgent;
    return /PhoenixContainer/.test(userAgent);
};
export const overrideBackNavigation = (destination) => {
    return isH5BridgeReady().then(() => {
        const JSBridge = getJSBridge();
        JSBridge.subscribe("back", function (e) {
            navigateToPageInApp(destination, null);
        });
    });
};
export const overrideBackNavigationBy = (cb) => {
    return isH5BridgeReady().then(() => {
        const JSBridge = getJSBridge();
        JSBridge.subscribe("back", cb);
    });
};
export const restoreBackNavigation = (cb) => {
    return isH5BridgeReady().then(() => {
        const JSBridge = getJSBridge();
        JSBridge.unsubscribe("back", cb);
    });
};
export const downloadFile = async (fileUrl, fileName) => {
    return new Promise((resolve, reject) => {
        isH5BridgeReady()
            .then(() => {
            const JSBridge = getJSBridge();
            JSBridge.call('paytmDownloadFile', {
                "fileDownloadUrl": fileUrl,
                "fileName": fileName,
                "useExternalStorage": 'false'
            }, function (result) {
                var _a, _b;
                const error = result.error ? result.message : (_b = (_a = result.data) === null || _a === void 0 ? void 0 : _a.error) === null || _b === void 0 ? void 0 : _b.message;
                if (error) {
                    return reject(new Error(error));
                }
                return resolve(result.data.filePath);
            });
        });
    });
};
export const saveImage = (imageUrl) => {
    return isH5BridgeReady().then(() => {
        return new Promise((resolve, reject) => {
            const JSBridge = getJSBridge();
            JSBridge.call("saveImage", { src: imageUrl, showActionSheet: true }, (result) => {
                if (result.success) {
                    return resolve();
                }
                const error = new Error(result.errorMessage);
                if (result.error === 2) {
                    error.name = "InvalidArgumentError";
                }
                if (result.error === 4) {
                    error.name = "InsufficientPermissionError";
                }
                if (result.error === 3) {
                    error.name = "FailedToRetrieveImageError";
                }
                reject(error);
            });
        });
    });
};
export const triggerAppGAAnalytics = (payload) => {
    return isH5BridgeReady().then(() => {
        const JSBridge = getJSBridge();
        JSBridge.call("logNativeAnalytics", payload);
    });
};

import { topMost } from "../../../styles/base/zIndex";
import { colors } from "../../../styles/common";
const deviceWidth = {
    medium: "768px"
};
const navHeight = {
    default: "60px",
    smallDevices: "56px"
};
export const borderColor = {
    blue: colors.primaryBlue,
    pink: colors.primaryPink,
    white: colors.white
};
export const backgroundColor = {
    blue: colors["brand-secondary-blue-light"],
    pink: colors["brand-secondary-pink-light"],
    white: colors["hover-primary-blue"]
};
const VerticalAlign = `
    display: flex;
    align-items: center;
`;
export const ManageHeight = `
    height: ${navHeight.default};

    @media only screen and (max-width:${deviceWidth.medium}){
        height: ${navHeight.smallDevices};
    }
`;
export const NavbarContainer = `
    ${ManageHeight}
	background-color: ${colors.primaryDark};
    transition: all 0.1s ease-in-out;
    display: flex;
    justify-content: space-between;
	width: 100%;
	position: fixed;
	top: 0;
    z-index: ${topMost};
`;
export const LeftContainer = `
    ${VerticalAlign}
    ${ManageHeight}
`;
export const RightContainer = `
	${VerticalAlign}
    ${ManageHeight}
	right: 0px;
	position: absolute;
	background-color: ${colors.primaryDark};
`;
export const MobileMenuStyles = `
	@media only screen and (min-width: ${deviceWidth.medium}) {
		display: none;
	} 
`;
export const HamburgerIcon = `
    ${VerticalAlign}
    height: ${navHeight.smallDevices};
    margin-left: 15px;

	@media screen and (max-width: 525px) {
		margin-left: 8px;
	}
`;
export const NavItemsContainer = `
${VerticalAlign}
    ${ManageHeight}
`;
export const NavItem = (activeColor) => `
	display: flex;
	align-items: center;
	padding: 7px 12px;
	height: 38px;
	margin-left: 15px;
	background: ${activeColor ? backgroundColor[activeColor] : "transparent"};
	border-radius: 100px;
	color: white;
	font-weight: 600;
	font-size: 13px;
	line-height: 150%;
    cursor: pointer;
    border: 1px solid ${activeColor ? borderColor[activeColor] : colors["border-grey"]};
    transition: box-shadow .3s;

    &:hover {
		border: 1px solid ${activeColor ? borderColor[activeColor] : "white"};
	}
`;
const getLeftNavMaxWidth = (isEDP, isEmptyCart) => {
    if (!isEmptyCart) {
        return "910";
    }
    else if ((!isEDP && !isEmptyCart) || (isEDP && isEmptyCart)) {
        return "800";
    }
    else {
        return "768";
    }
};
export const HideLeftNav = (isEDP, isEmptyCart) => `
	@media screen and (max-width: ${getLeftNavMaxWidth(isEDP, isEmptyCart)}px) {
		display: none;
	}
`;
export const HideListYourEvent = `
	@media screen and (max-width: 1024px) {
		display: none;
	}
`;

export const checkValidEvent = (event) => {
    const eventEndUTCTimestamp = event.get("max_show_end_utc_timestamp") || event.get("end_utc_timestamp");
    // Only if max_show_end_utc_timestamp exists for event then add comparison check (end_utc_timestamp for older cache)
    // else skip event directly
    if (eventEndUTCTimestamp) {
        const eventEndTime = new Date(0).setUTCSeconds(eventEndUTCTimestamp);
        const currentUTCTime = new Date().getTime();
        return eventEndTime > currentUTCTime;
    }
    return false;
};

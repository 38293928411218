import { connect } from "react-redux";
import { withRouter } from "react-router";
// components
import UserNavbar from "../components/header/AppBar/UserNavbar";
// actions + selectors
import { clearNotifications, resetModalType, setLoginError, setModalRedirectUrl, setModalSource, setModalType, setNotifications, setUserLoginData } from "../actions/index";
import { getModalType, isUserLoggedIn, loginModalRedirectUrl } from "../selectors/index";
const mapStateToProps = (state, ownProps) => ({
    isLoggedIn: isUserLoggedIn(state),
    modalType: getModalType(state),
    redirectUrl: loginModalRedirectUrl(state),
    isUserSelectingCity: ownProps.isUserSelectingCity
});
const mapDispatchToProps = (dispatch) => ({
    clearNotifications: () => dispatch(clearNotifications()),
    notify: notification => dispatch(setNotifications(notification)),
    resetModalType: () => dispatch(resetModalType()),
    setLoginError: msg => dispatch(setLoginError(msg)),
    setModalRedirectUrl: url => dispatch(setModalRedirectUrl(url)),
    setModalSource: source => dispatch(setModalSource(source)),
    setModalType: payload => dispatch(setModalType(payload)),
    setUserLoginData: userInfo => dispatch(setUserLoginData(userInfo))
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UserNavbar));

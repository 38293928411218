import { Map, List, fromJS } from "immutable";
import { RECEIVE_POPULAR_SEARCH_DATA, ERROR_POPULAR_SEARCH_DATA, RECEIVE_RECENT_SEARCH_DATA, ERROR_RECENT_SEARCH_DATA, REQUEST_USER_SEARCH_DATA, RECEIVE_USER_SEARCH_DATA, CLEAR_USER_SEARCH_DATA, ERROR_USER_SEARCH_DATA, REQUEST_ALGOLIA_SEARCH_DATA, RECEIVE_ALGOLIA_SEARCH_DATA, ERROR_ALGOLIA_SEARCH_DATA, UPDATE_ALGOLIA_INDEX_DATA, CLEAR_ALGOLIA_SEARCH_DATA, RECEIVE_QUICK_SEARCH_FILTER, ERROR_QUICK_SEARCH_FILTER, SET_SEARCH_FILTER_APPLIED } from "../actions/index";
import { POPULAR_SEARCH_RESULTS_COUNT, RECENT_SEARCH_RESULTS_COUNT } from "../helpers/constants";
const initialState = new Map({
    popular: new List([]),
    recent: new List([]),
    user: new List([]),
    showUserSearch: false,
    prevSearchQuery: "",
    isSearching: true,
    isError: false,
    resultsState: new Map({}),
    quickSearchFilters: new List([])
});
const searchData = (state = initialState, action) => {
    switch (action.type) {
        case RECEIVE_POPULAR_SEARCH_DATA: {
            return state.set("popular", action.payload.take(POPULAR_SEARCH_RESULTS_COUNT));
        }
        case ERROR_POPULAR_SEARCH_DATA: {
            return state.set("popular", initialState.get("popular"));
        }
        case RECEIVE_RECENT_SEARCH_DATA: {
            return state.set("recent", action.payload.take(RECENT_SEARCH_RESULTS_COUNT));
        }
        case ERROR_RECENT_SEARCH_DATA: {
            return state.set("recent", initialState.get("recent"));
        }
        case REQUEST_USER_SEARCH_DATA: {
            return state.set("isSearching", true);
        }
        case RECEIVE_ALGOLIA_SEARCH_DATA: {
            // maintain algolia search results format here
            return state.withMutations(s => s.set("resultsState", action.payload).set("isSearching", false));
        }
        case UPDATE_ALGOLIA_INDEX_DATA: {
            // maintain algolia search results format here
            return state.withMutations(s => s
                .setIn(["resultsState", action.payload.type, "hits"], action.payload.hits)
                .setIn(["resultsState", action.payload.type, "queryID"], action.payload.queryID)
                .set("isSearching", false));
        }
        case CLEAR_ALGOLIA_SEARCH_DATA: {
            return state.withMutations(s => s.set("isSearching", true)
            //.set('resultsState', new Map({}))
            );
        }
        case RECEIVE_USER_SEARCH_DATA: {
            return state.withMutations(s => s
                .set("user", action.payload.get("results"))
                .set("showUserSearch", true)
                .set("prevSearchQuery", action.payload.get("query"))
                .set("isSearching", false)
                .set("isError", false));
        }
        case ERROR_USER_SEARCH_DATA: {
            return state.withMutations(s => s.set("user", initialState.get("user")).set("isError", true));
        }
        case CLEAR_USER_SEARCH_DATA: {
            return state.withMutations(s => s
                .set("user", initialState.get("user"))
                .set("showUserSearch", initialState.get("showUserSearch"))
                .set("prevSearchQuery", initialState.get("prevSearchQuery")));
        }
        case RECEIVE_QUICK_SEARCH_FILTER: {
            return state.set("quickSearchFilters", fromJS(action.payload).sort((a, b) => a.get("position") - b.get("position")));
        }
        case ERROR_QUICK_SEARCH_FILTER: {
            return state.set("quickSearchFilters", initialState.get("quickSearchFilters"));
        }
        case SET_SEARCH_FILTER_APPLIED: {
            return state.set("filterApplied", fromJS(action.payload));
        }
        default:
            return state;
    }
};
export default searchData;

import React from "react";
import { connect } from "react-redux";
import { getUserProfile, getIsSPA } from "../selectors/index";
import UserIcon from "../components/header/UserIcon";
import { withRouter } from "react-router";
const mapStateToProps = (state, ownProps) => {
    return {
        userInfo: getUserProfile(state),
        isSPA: getIsSPA(state)
    };
};
export default withRouter(connect(mapStateToProps)(UserIcon));
